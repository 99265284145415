import { PencilIcon } from "@heroicons/react/outline";
import { compact } from "lodash";
import moment from "moment";
import { useCallback, useState } from "react";
import { MeetingDialogFragmentFragment } from "types/graphql-schema";

import MeetingDialog, {
  meetingDialogAction,
} from "@apps/meeting-dialog/meeting-dialog";
import MeetingDropdownMenu from "@apps/meeting-dropdown-menu/meeting-dropdown-menu";
import MeetingVideoConferenceButton from "@apps/meeting-new/components/meeting/video-conference-button";
import { currentUserVar } from "@cache/cache";
import Avatar from "@components/avatar/avatar";
import Avatars from "@components/avatar/avatars";
import Button, { buttonTheme } from "@components/button/button";
import Layout from "@components/layout/layout";
import AppLink from "@components/link/link";
import { classNames } from "@helpers/css";
import { assertEdgesNonNullWithStringId, getUrl } from "@helpers/helpers";

const OverviewMeeting = ({
  meeting,
  onMeetingUpdated,
}: {
  meeting: MeetingDialogFragmentFragment;
  onMeetingUpdated: (data: { meeting: MeetingDialogFragmentFragment }) => void;
}) => {
  const [isShowingMeetingDialog, setIsShowingMeetingDialog] = useState(false);
  const currentUser = currentUserVar();
  const participants = assertEdgesNonNullWithStringId(meeting.participants);
  const otherParticipant = participants.find(
    (participant) => participant.user && participant.user.id !== currentUser.id
  );
  const isPast = meeting.endDatetime && moment().isAfter(meeting.endDatetime);

  const handleCloseModal = useCallback(() => {
    setIsShowingMeetingDialog(false);
  }, []);

  return (
    <>
      {isShowingMeetingDialog && (
        <MeetingDialog
          meetingId={meeting.id}
          meetingGroupId={meeting.meetingGroup?.id}
          onClose={handleCloseModal}
          defaultAction={meetingDialogAction.edit}
          onSaved={onMeetingUpdated}
        />
      )}
      <div
        className={classNames(
          "border-t border-gray-300 text-sm",
          isPast && "text-gray-400",
          meeting.draft && "bg-yellow-50"
        )}
      >
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <div className="m-4 w-[110px]">
              {`${moment(meeting.startDatetime).format("h:mm")} - ${moment(
                meeting.endDatetime
              ).format("h:mma")}`}
            </div>
            <AppLink
              className="flex-1 m-4 font-medium hover:underline"
              key={meeting.id}
              to={getUrl({
                meetingGroupId: meeting.meetingGroupId,
                meetingId: meeting.id,
              })}
            >
              {meeting.title}
            </AppLink>
          </div>

          <div className="flex items-center gap-2 pr-4">
            {meeting.draft && (
              <div className="px-2 py-1 bg-yellow-200 text-slate-600 rounded-md">
                Draft
              </div>
            )}
            {meeting.videoConferenceUrl &&
              meeting.startDatetime &&
              moment().isBetween(
                moment(meeting.startDatetime).subtract(5, "minutes"),
                meeting.endDatetime
              ) && (
                <div className="text-sm text-gray-700 flex">
                  <MeetingVideoConferenceButton
                    meeting={meeting}
                    className="hover:text-gray-800 hover:bg-black/5 text-xs px-1 py-0.5 rounded text-gray-600 gap-1.5"
                  />
                </div>
              )}
            {meeting.meetingGroup?.isFormalOneonone && otherParticipant ? (
              <Avatar user={otherParticipant.user} size={4} />
            ) : (
              <Avatars
                max={3}
                users={compact(participants.map(({ user }) => user))}
                className={classNames(
                  "flex items-center",
                  participants.length === 1 ? "w-5" : "pl-2" // otherwise single avatar gets squished
                )}
                avatarClassName={classNames("contrast-100 rounded-full -ml-1")}
                extraClassName="w-6 h-6 rounded-full -ml-1.5 text-2xs flex items-center justify-center z-1 bg-gray-100 text-gray-600"
                size="5"
              />
            )}
            <Button
              theme={buttonTheme.text}
              icon
              className="text-inherit"
              onClick={() => setIsShowingMeetingDialog(true)}
            >
              <PencilIcon className="h-4 w-4" />
            </Button>
            {meeting.meetingGroup && (
              <MeetingDropdownMenu
                meeting={meeting}
                meetingGroup={meeting.meetingGroup}
                isContextMenu={true}
                className={Layout.headerIconButtonClassName}
                size="5"
                onMeetingUpdated={onMeetingUpdated}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default OverviewMeeting;
