import { gql } from "@apollo/client";

import { MeetingDialogFragment } from "@apps/meeting-dialog/graphql/meeting-dialog-fragment";

export default gql`
  ${MeetingDialogFragment}
  query getMeetingOverviewMeetingsQuery(
    $forUserId: Int!
    $dateRangeStart: DateTime!
    $dateRangeEnd: DateTime!
    $isOneonone: Boolean
  ) {
    calendar(
      forUserId: $forUserId
      ignored: false
      startDatetime_Gte: $dateRangeStart
      startDatetime_Lte: $dateRangeEnd
      orderBy: "start_datetime"
      isFormalOneonone: $isOneonone
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
      }
      edges {
        node {
          id
          meeting {
            ...MeetingDialogFragment
          }
        }
      }
    }
    unscheduledMeetings: calendar(forUserId: $forUserId, unscheduled: true) {
      totalCount
      edges {
        node {
          id
          meeting {
            ...MeetingDialogFragment
          }
        }
      }
    }
  }
`;
