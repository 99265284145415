import { useQuery, useReactiveVar } from "@apollo/client";
import { Portal } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { compact } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { usePopper } from "react-popper";
import { useLocation } from "react-router-dom";
import {
  ArtifactType,
  CommentNode,
  GetLastCheckinActivityQueryQuery,
  GetLastCheckinActivityQueryQueryVariables,
  GoalArtifactNode,
} from "types/graphql-schema";
import { TFLocationState } from "types/topicflow";

import GoalKeyResultList from "@apps/artifact-sidebar/components/goal-key-result-list";
import CheckinDialog from "@apps/checkin-dialog/checkin-dialog";
import useLabel from "@apps/use-label/use-label";
import { checkinPopoverGoalIdVar } from "@cache/cache";
import Button, { buttonTheme } from "@components/button/button";
import GoalProgressBar from "@components/goal-progress-bar/goal-progress-bar";
import GoalProgressPill from "@components/goal-progress-pill/goal-progress-pill";
import GoalStatusPill from "@components/goal-status-pill/goal-status-pill";
import AppLink from "@components/link/link";
import Loading from "@components/loading/loading";
import ProgressBar from "@components/progress-bar/progress-bar";
import TimeAgoCustom from "@components/time-ago/time-ago";
import StartCurrentTargetTooltip from "@components/tooltip/start-current-target-tooltip";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import useOutsideClick from "@components/use-outside-click/use-outside-click";
import CommentWYSIWYG from "@components/wysiwyg/comment-wysiwyg";
import {
  assertEdgesNonNull,
  getProgressLabel,
  getUrl,
  parseStringToJSON,
  toWithBackground,
} from "@helpers/helpers";

import getLastCheckinActivityQuery from "./graphql/get-last-checkin-activity-query";

const enterDelay = 700;
const placement = "bottom";

type PopperRef = HTMLDivElement | HTMLButtonElement | null;
export enum GoalProgressPillView {
  pillOnly = "pillOnly",
  pillAndProgressText = "pillAndProgressText",
  pillAndProgressBar = "pillAndProgressBar",
}

const GoalProgressPillWithPopover = ({
  artifact,
  pillView = GoalProgressPillView.pillAndProgressText,
}: {
  artifact: Pick<
    GoalArtifactNode,
    | "id"
    | "state"
    | "status"
    | "progress"
    | "startValue"
    | "targetValue"
    | "progressType"
    | "currentValue"
    | "isStale"
    | "title"
  > & {
    canUpdate: {
      permission: boolean;
    };
  };
  pillView?: GoalProgressPillView;
}) => {
  const label = useLabel();
  const location = useLocation<TFLocationState>();
  const checkinPopoverGoalId = useReactiveVar(checkinPopoverGoalIdVar);
  const [isShowingCheckinDialog, setIsShowingCheckinDialog] = useState(false);
  const [isShowingLastCheckinPopover, setIsShowingLastCheckinPopover] =
    useState(false);
  const [referenceElement, setReferenceElement] = useState<PopperRef>(null);
  const [popperElement, setPopperElement] = useState<PopperRef>(null);
  const [arrowElement, setArrowElement] = useState<PopperRef>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement,
    modifiers: [
      { name: "offset", options: { offset: [0, 10] } },
      { name: "arrow", options: { element: arrowElement } },
      {
        name: "preventOverflow",
        options: {
          altAxis: true,
          padding: 16,
        },
      },
    ],
  });
  const enterTimeout = useRef<NodeJS.Timeout>();
  const { data, loading } = useQuery<
    GetLastCheckinActivityQueryQuery,
    GetLastCheckinActivityQueryQueryVariables
  >(getLastCheckinActivityQuery, {
    variables: { artifactId: artifact.id },
    skip: !isShowingLastCheckinPopover,
    onError: onNotificationErrorHandler(),
  });
  const handleCloseCheckinPopover = () => {
    setIsShowingLastCheckinPopover(false);
  };
  const clickOutsideRef = useOutsideClick(handleCloseCheckinPopover);
  const goal = data?.artifact as GoalArtifactNode;
  const activities = goal?.activities
    ? assertEdgesNonNull(goal.activities)
    : [];
  const lastCheckin = activities[0];
  const changedFields = compact(lastCheckin?.changedFields || []);
  const commentChangedField = changedFields.find(
    (changedField) => changedField?.fieldName === "comment"
  );
  const comment = (commentChangedField?.newValueObject as CommentNode) || null;

  const handleMouseEnter = useCallback(() => {
    const showLastCheckinPopover = () => {
      setIsShowingLastCheckinPopover(true);
      checkinPopoverGoalIdVar(artifact.id);
    };
    enterTimeout.current = setTimeout(showLastCheckinPopover, enterDelay);
  }, [artifact.id]);

  const handleMouseLeave = useCallback(() => {
    if (!isShowingLastCheckinPopover) {
      if (enterTimeout.current) {
        clearTimeout(enterTimeout.current);
      }
      setIsShowingLastCheckinPopover(false);
    }
  }, [isShowingLastCheckinPopover]);

  const handleClickShowCheckinDialog = () => {
    setIsShowingLastCheckinPopover(false);
    setIsShowingCheckinDialog(true);
  };

  useEffect(() => {
    if (checkinPopoverGoalId !== artifact.id) {
      handleCloseCheckinPopover();
    }
  }, [checkinPopoverGoalId, artifact.id]);

  return (
    <>
      {isShowingCheckinDialog && (
        <CheckinDialog
          artifact={artifact}
          onClose={() => setIsShowingCheckinDialog(false)}
        />
      )}
      <div>
        <button
          className="hidden group sm:flex items-center gap-2.5 relative"
          onClick={handleClickShowCheckinDialog}
          disabled={!artifact.canUpdate.permission}
          ref={setReferenceElement}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {(pillView === GoalProgressPillView.pillOnly ||
            pillView === GoalProgressPillView.pillAndProgressBar) && (
            <GoalStatusPill
              status={artifact.status}
              state={artifact.state}
              isStale={artifact.isStale}
            />
          )}
          {pillView === GoalProgressPillView.pillAndProgressBar && (
            <ProgressBar
              label={getProgressLabel(artifact, { shortVersion: true })}
              progress={artifact.progress}
            />
          )}
          {pillView === GoalProgressPillView.pillAndProgressText && (
            <GoalProgressPill
              isStale={artifact.isStale}
              state={artifact.state}
              status={artifact.status}
              progress={artifact.progress}
              hideTooltip
            />
          )}
        </button>
        {isShowingLastCheckinPopover && (
          <Portal>
            <div
              className="fixed z-modal w-full max-w-lg bg-white rounded-lg border shadow-lg"
              ref={setPopperElement}
              style={styles.popper}
              {...attributes.popper}
            >
              <div ref={setArrowElement} style={styles.arrow} id="arrow" />
              <div
                className="bg-white rounded-lg flex flex-col gap-4 py-3 px-4"
                ref={clickOutsideRef}
              >
                <div className="flex items-center justify-between">
                  <AppLink
                    to={toWithBackground({
                      pathname: getUrl({
                        artifactId: artifact.id,
                        artifactType: ArtifactType.Goal,
                      }),
                      location: location,
                    })}
                    className="text-lg font-medium hover:underline"
                  >
                    {artifact.title}
                  </AppLink>
                  <button
                    onClick={() => setIsShowingLastCheckinPopover(false)}
                    className="text-gray-500 hover:text-gray-800 focus:ring-0 active:ring-0 focus:outline-none active:outline-none"
                  >
                    <XIcon className="h-6 w-6 " />
                  </button>
                </div>
                <div className="text-sm">
                  {loading ? (
                    <Loading />
                  ) : goal ? (
                    <div className="flex flex-col gap-6">
                      <div>
                        <StartCurrentTargetTooltip goalOrKeyresult={artifact}>
                          <div className="h-6">
                            <GoalProgressBar
                              goal={artifact}
                              canUpdateProgress={artifact.canUpdate.permission}
                              onClickUpdateProgress={() =>
                                setIsShowingCheckinDialog(true)
                              }
                            />
                          </div>
                        </StartCurrentTargetTooltip>
                      </div>
                      <GoalKeyResultList goalArtifact={goal} isReadOnly />
                      {goal && lastCheckin && comment && (
                        <div>
                          <div>
                            <AppLink
                              to={getUrl({ userId: lastCheckin.actor?.id })}
                              className="font-medium hover:underline"
                            >
                              {lastCheckin.actor?.name || "A user"}
                            </AppLink>{" "}
                            updated this {label("goal")}{" "}
                            <span className="text-gray-500">
                              <TimeAgoCustom date={lastCheckin.created} />
                            </span>
                          </div>
                          <CommentWYSIWYG
                            key={comment.comment} // force re-rendering
                            mentionsConfig={{
                              artifactId: goal.id,
                            }}
                            uploadVariable={{
                              artifactId: goal.id,
                            }}
                            value={parseStringToJSON(comment.comment)}
                            className="mt-1"
                          />
                        </div>
                      )}
                    </div>
                  ) : (
                    <div>This {label("goal")} does not have any updates.</div>
                  )}
                </div>
                {artifact.canUpdate.permission && (
                  <div className="flex items-center gap-2 pt-2">
                    <Button
                      theme={buttonTheme.primary}
                      text="Update progress"
                      onClick={handleClickShowCheckinDialog}
                      small
                    />
                  </div>
                )}
              </div>
            </div>
          </Portal>
        )}
      </div>
    </>
  );
};

export default GoalProgressPillWithPopover;
