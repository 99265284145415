import { sortBy } from "lodash";
import {
  MeetingViewMeetingNodeNewPageFragmentFragment,
  TopicNodeNewPageFragmentFragment,
} from "types/graphql-schema";

import { currentUserVar } from "@cache/cache";
import { classNames } from "@helpers/css";
import {
  assertEdgesNonNull,
  assertEdgesNonNullWithStringId,
} from "@helpers/helpers";

import IndividualNotes from "./individual-notes";

const IndividualNotesContainer = ({
  topic,
  meeting,
  className = "",
}: {
  topic: TopicNodeNewPageFragmentFragment;
  meeting: MeetingViewMeetingNodeNewPageFragmentFragment;
  className?: string;
}) => {
  const currentUser = currentUserVar();

  const individualNotes = assertEdgesNonNull(topic.individualNotes);
  const enabledNotes = individualNotes.filter(
    (individualNote) => individualNote.enabled
  );
  const participantIdsWithNotes = enabledNotes.map(
    (individualNote) => individualNote.creator.id
  );

  const meetingGroup = meeting.meetingGroup;
  if (!meetingGroup) return null;

  const participants = assertEdgesNonNullWithStringId(meeting.participants);
  return (
    <div className={classNames("flex flex-col gap-4 empty:hidden", className)}>
      {sortBy(
        participants,
        (participant) => participant.user?.id !== currentUser.id // put current user first.
      )
        .filter(
          (participant) =>
            !!participant.user?.id &&
            (participantIdsWithNotes.includes(participant.user?.id) ||
              meetingGroup.isFormalOneonone)
        )
        .map((participant) =>
          !participant.user ? null : (
            <IndividualNotes
              key={participant.id}
              participant={participant}
              topic={topic}
              meeting={meeting}
            />
          )
        )}
    </div>
  );
};

export default IndividualNotesContainer;
