import { PlusIcon, XIcon } from "@heroicons/react/outline";
import { useState } from "react";
import { MeetingViewMeetingNodeNewPageFragmentFragment } from "types/graphql-schema";

import TopicCreateForm from "./topic-create-form";

const AddTopicHover = ({
  meeting,
  afterTopicId,
}: {
  meeting: MeetingViewMeetingNodeNewPageFragmentFragment;
  afterTopicId: number;
}) => {
  const [showCreateForm, setShowCreateForm] = useState(false);
  return showCreateForm ? (
    <div className="border-t relative">
      <button
        onClick={() => setShowCreateForm(false)}
        className="text-gray-500 rounded-full border p-0.5 hover:bg-gray-100 absolute top-0.5 right-0.5"
      >
        <XIcon className="h-3 w-3" />
      </button>
      <TopicCreateForm
        key={`create-topic-form-${meeting.id}`}
        meetingGroup={meeting.meetingGroup}
        meeting={meeting}
        afterTopicId={afterTopicId}
        onCreated={() => setShowCreateForm(false)}
      />
    </div>
  ) : meeting.canUpdate ? (
    <div className="relative bg-white h-px py-4 w-full group/add-topic">
      <div className="h-px bg-gray-200" />
      <button
        onClick={() => setShowCreateForm(true)}
        className="bg-white text-gray-600 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 border rounded-full p-1.5 opacity-0 group-hover/add-topic:opacity-100 hover:bg-gray-100"
      >
        <PlusIcon className="h-4 w-4" />
      </button>
    </div>
  ) : null;
};

export default AddTopicHover;
