import { gql } from "@apollo/client";

import favouritesGroupFragment from "@apps/dashboard-new/graphql/favourites-group-fragment";

export const ExplorerBookmarkFragment = gql`
  fragment ExplorerBookmarkFragment on ExplorerFilterBookmarkNode {
    id
    title
    filters
    private
    canUpdate {
      permission
    }
    canDelete {
      permission
    }
  }
`;

export const FavouriteArtifactFragment = gql`
  fragment FavouriteArtifactFragment on ArtifactInterface {
    id
  }
`;

export default gql`
  ${favouritesGroupFragment}
  ${ExplorerBookmarkFragment}
  ${FavouriteArtifactFragment}
  fragment LoggedInUserOrg on OrganizationNode {
    id
    name
    topicChannelName
    personal
    goalLabel
    keyResultLabel
    teamLabel
    orgLabel
    recognitionLabel
    oneononeLabel
    reviewLabel
    expectationLabel
    conversationLabel
    developmentLabel
    daysLeftOnTrial
    pricingTier
    quarterStartMonth
    enableMeetingSummarization
    effectivePricingTier
    defaultGoalState
    defaultDecisionState
    userMembership {
      id
      role
    }
    actionItemStates {
      value
      label
      isComplete
      isDefaultIncomplete
    }
    coreValues(status: active) {
      edges {
        node {
          id
          title
          description
          emoji
          image
        }
      }
    }
    featureFlags {
      actionItems
      feedbacks
      decisions
      goals
      documents
      recognitions
      kpis
      explorer
      homepageMyConnections
    }
  }
  query getLoggedInUser {
    loggedIn
    me {
      id
      email
      emails
      name
      firstName
      lastName
      status
      avatar
      hasAsanaAuth
      hasHubspotAuth
      hasGithubAuth
      hasJiraAuth
      hasZoomAuth
      hasGoogleSheetsAuth
      hasSalesforceAuth
      eventLinkInDescriptionPreference
      timezone
      pusherChannelName
      hasSyncedCalendar
      uiPreferenceCache
      explorerBookmarks {
        edges {
          node {
            ...ExplorerBookmarkFragment
          }
        }
      }
      favouriteArtifacts {
        edges {
          node {
            ...FavouriteArtifactFragment
          }
        }
      }
      favouritesGroups {
        edges {
          node {
            ...FavouritesGroupFragment
          }
        }
      }
      syncCredentials {
        edges {
          node {
            id
            provider
            credentialsUid
            hasValidCalendarCredentials
            hasCalendarWriteCredentials
            organization {
              id
              domains
            }
          }
        }
      }
      onlyUserInOrg
      calendarIsSyncingForFirstTime
      hasCalendarSyncError
      organizations {
        edges {
          node {
            ...LoggedInUserOrg
          }
        }
      }
      paidFeatures {
        canCreateDecisions
        canCreateGoals
        canCreateGroupGoals
        canCreateOrgTemplates
      }
      hasOrgWithTooManyUsers
      directReports {
        edges {
          node {
            id
            name
            avatar
            email
          }
        }
      }
      managers {
        edges {
          node {
            id
            name
            avatar
            email
          }
        }
      }
      teams {
        edges {
          node {
            id
            title
          }
        }
      }
    }
  }
`;
