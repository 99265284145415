import { Draggable } from "@hello-pangea/dnd";
import { TrashIcon } from "@heroicons/react/outline";
import { Editor, JSONContent } from "@tiptap/core";
import { isString } from "lodash";
import { ChangeEvent, KeyboardEvent, useState } from "react";
import { MdOutlineDragIndicator } from "react-icons/md";

import { currentOrganizationVar } from "@cache/cache";
import Button from "@components/button/button";
import Label from "@components/label/label";
import { Select, SelectOption } from "@components/select/select";
import TextareaAutosize from "@components/textarea-autosize/textarea-autosize";
import InfoTooltip from "@components/tooltip/info-tooltip";
import TemplateTopicWYSIWYG from "@components/wysiwyg/template-topic-wysiwyg";
import {
  classNames,
  inputBorderClassName,
  inputFocusClassName,
} from "@helpers/css";
import { isEnterEvent } from "@helpers/helpers";

import { NotesRequirement } from "../../../types/graphql-schema";
import { EditTemplateTopicType } from "../helpers";

type TopicRequirementOption = SelectOption<number> & {
  isMandatory: boolean;
  notesRequirement: NotesRequirement;
};

export const topicRequirementsOptions = [
  {
    value: 1,
    label: "Not mandatory",
    description: "Topic title can be edited.",
    isMandatory: false,
    notesRequirement: NotesRequirement.NotRequired,
  },
  {
    value: 2,
    label: "Mandatory, notes optional",
    description:
      "Topic title cannot be edited. No notes are required to finalize.",
    isMandatory: true,
    notesRequirement: NotesRequirement.NotRequired,
  },
  {
    value: 3,
    label: "Mandatory, notes required by one participant",
    description:
      "Topic title cannot be edited. Notes must be entered by one participant to finalize.",
    isMandatory: true,
    notesRequirement: NotesRequirement.OneParticipant,
  },
  {
    value: 4,
    label: "Mandatory, notes required by all participants",
    description:
      "Topic title cannot be edited. Notes must be entered by all participants to finalize.",
    isMandatory: true,
    notesRequirement: NotesRequirement.AllParticipants,
  },
] as TopicRequirementOption[];

const EditTopic = ({
  index,
  topic,
  oneononeTemplate,
  showDelete,
  onChangeTitle,
  onChangeDiscussionNotes,
  onChangeDefaultSubjectNotes,
  onChangeDefaultFacilitatorNotes,
  onChangeIncludesIndividualNotes,
  onChangeIncludesSharedNotes,
  onChangeTopicRequirements,
  onDelete,
}: {
  index: number;
  topic: EditTemplateTopicType;
  showDelete: boolean;
  oneononeTemplate: boolean;
  onChangeTitle: (
    event: ChangeEvent<HTMLTextAreaElement>,
    id: number | string
  ) => void;
  onChangeDiscussionNotes: (
    discussionNotes: JSONContent,
    id: number | string
  ) => void;
  onChangeDefaultSubjectNotes: (
    defaultSubjectNotes: JSONContent,
    id: number | string
  ) => void;
  onChangeDefaultFacilitatorNotes: (
    defaultFacilitatorNotes: JSONContent,
    id: number | string
  ) => void;
  onChangeIncludesIndividualNotes: (
    event: ChangeEvent<HTMLInputElement>,
    id: number | string
  ) => void;
  onChangeIncludesSharedNotes: (
    event: ChangeEvent<HTMLInputElement>,
    id: number | string
  ) => void;
  onChangeTopicRequirements: (
    isMandatory: boolean,
    notesRequirement: NotesRequirement,
    id: number | string
  ) => void;
  onDelete: (id: number | string) => void;
}) => {
  const [showInputs, setShowInputs] = useState(
    index === 0 || topic.title.length > 0
  );
  const organization = currentOrganizationVar();
  const handleKeyDownPreventEnter = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (isEnterEvent(e)) {
      e.preventDefault();
      e.stopPropagation();
      return false;
    }
  };

  const handleChangeDefaultSharedNotes = ({ editor }: { editor: Editor }) => {
    onChangeDiscussionNotes(editor.getJSON(), topic.id);
  };

  const handleChangeDefaultSubjectNotes = ({ editor }: { editor: Editor }) => {
    onChangeDefaultSubjectNotes(editor.getJSON(), topic.id);
  };

  const handleChangeDefaultFacilitatorNotes = ({
    editor,
  }: {
    editor: Editor;
  }) => {
    onChangeDefaultFacilitatorNotes(editor.getJSON(), topic.id);
  };

  const handleChangeTopicRequirements = (option: SelectOption<number>) => {
    const matchingOption = topicRequirementsOptions.find(
      ({ value }) => value === option.value
    );
    if (matchingOption) {
      const isMandatory = matchingOption.isMandatory;
      const notesRequirement = matchingOption.notesRequirement;

      onChangeTopicRequirements(isMandatory, notesRequirement, topic.id);
    }
  };

  const currentTopicRequirement =
    topicRequirementsOptions.find(
      ({ isMandatory, notesRequirement }) =>
        topic.isMandatory === isMandatory &&
        topic.notesRequirement === notesRequirement
    ) || topicRequirementsOptions[0];
  const currentTopicRequirementValue = currentTopicRequirement.value;
  return (
    <Draggable
      key={topic.id}
      draggableId={String(topic.id)}
      isDragDisabled={isString(topic.id) && topic.title === ""}
      index={index}
    >
      {(provided) => (
        <li
          ref={provided.innerRef}
          {...provided.draggableProps}
          className="px-4 py-6 flex gap-4 relative flex-1"
          key={topic.id}
        >
          <div className="w-7">
            {topic.title && (
              <div className="cursor-grab px-1" {...provided.dragHandleProps}>
                <MdOutlineDragIndicator className="h-5 w-5 text-gray-400" />
              </div>
            )}
          </div>
          {showInputs ? (
            <div className="flex flex-col gap-6 flex-1">
              <div>
                <Label label="Topic title" />
                <TextareaAutosize
                  value={topic.title}
                  className={classNames(
                    inputBorderClassName,
                    inputFocusClassName,
                    "flex-1 block w-full font-medium py-2 px-4 resize-none placeholder:font-normal"
                  )}
                  onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                    onChangeTitle(e, topic.id)
                  }
                  onKeyDown={handleKeyDownPreventEnter}
                  minRows={1}
                  placeholder="Type topic title..."
                  aria-label="Template topic title"
                  autoFocus
                />
              </div>
              {!oneononeTemplate && topic.title && (
                <div>
                  <Label label="Default notes" />
                  <TemplateTopicWYSIWYG
                    key={`template-topic-${topic.id}`}
                    value={topic.discussionNotes}
                    className={classNames(
                      inputBorderClassName,
                      inputFocusClassName,
                      "w-full block",
                      "text-base",
                      "py-1 px-4" // adding bottom padding here so the white space is clickable
                    )}
                    editable
                    placeholder="Add default notes"
                    onUpdateContent={handleChangeDefaultSharedNotes}
                    uploadVariable={{ topicId: topic.id }}
                    mentionsConfig={{}}
                    extraContext={{
                      organizationId: organization.id,
                    }}
                  />
                </div>
              )}

              {oneononeTemplate && topic.title && (
                <>
                  <div>
                    <Label label="Facilitator default notes" />
                    <TemplateTopicWYSIWYG
                      key={`facilitator-template-topic-${topic.id}`}
                      value={topic.defaultFacilitatorNotes}
                      className={classNames(
                        inputBorderClassName,
                        inputFocusClassName,
                        "w-full block",
                        "text-base",
                        "py-1 px-4" // adding bottom padding here so the white space is clickable
                      )}
                      editable
                      placeholder="Add default notes for facilitator"
                      onUpdateContent={handleChangeDefaultFacilitatorNotes}
                      uploadVariable={{ topicId: topic.id }}
                      mentionsConfig={{}}
                      extraContext={{ organizationId: organization.id }}
                    />
                  </div>
                  <div>
                    <Label label="Subject default notes" />
                    <TemplateTopicWYSIWYG
                      key={`subject-template-topic-${topic.id}`}
                      value={topic.defaultSubjectNotes}
                      className={classNames(
                        inputBorderClassName,
                        inputFocusClassName,
                        "w-full block",
                        "text-base",
                        "py-1 px-4" // adding bottom padding here so the white space is clickable
                      )}
                      editable
                      placeholder="Add default notes for subject"
                      onUpdateContent={handleChangeDefaultSubjectNotes}
                      uploadVariable={{ topicId: topic.id }}
                      mentionsConfig={{}}
                      extraContext={{ organizationId: organization.id }}
                    />
                  </div>
                </>
              )}

              {!oneononeTemplate && topic.title && (
                <div className="text-xs text-gray-500 flex items-center">
                  <Label label="Note type:" className="mb-0 w-24" />
                  <label className="flex gap-1.5 items-center cursor-pointer">
                    <input
                      type="checkbox"
                      aria-label="Topic includes shared note checkbox"
                      checked={topic.includesSharedNotes}
                      onChange={(e) => onChangeIncludesSharedNotes(e, topic.id)}
                    />
                    shared
                    <InfoTooltip
                      size="4"
                      text="Meeting participants work together in real-time within a shared note field."
                    />
                  </label>
                  <label className="flex gap-1.5 items-center cursor-pointer ml-6">
                    <input
                      aria-label="Topic includes individual note checkbox"
                      type="checkbox"
                      checked={topic.includesIndividualNotes}
                      onChange={(e) =>
                        onChangeIncludesIndividualNotes(e, topic.id)
                      }
                    />
                    individual
                    <InfoTooltip
                      size="4"
                      text="Each meeting participant has their own designated field."
                    />
                  </label>
                </div>
              )}

              {(waffle.flag_is_active(
                "show-mandatory-dropdown-for-meeting-template"
              ) ||
                oneononeTemplate) &&
                topic.title && (
                  <div className="flex flex-col">
                    <Label label="Mandatory" />
                    <div className="max-w-96">
                      <Select<number>
                        aria-label="Topic mandatory dropdown"
                        onChange={handleChangeTopicRequirements}
                        value={currentTopicRequirementValue}
                        options={topicRequirementsOptions}
                      />
                    </div>
                    <label className="mt-2 flex gap-1.5 items-center cursor-pointer text-xs text-gray-500">
                      {currentTopicRequirement.description}
                    </label>
                  </div>
                )}
            </div>
          ) : (
            <div>
              <Button
                small
                onClick={() => setShowInputs(true)}
                text="Add topic"
              />
            </div>
          )}
          {showDelete && (
            <div>
              <button
                className="text-gray-400 hover:text-rose-600 px-2"
                onClick={() => onDelete(topic.id)}
                aria-label="Delete template topic"
              >
                <TrashIcon className="w-4 h-4" />
              </button>
            </div>
          )}
        </li>
      )}
    </Draggable>
  );
};

export default EditTopic;
