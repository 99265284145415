import { useQuery } from "@apollo/client";
import moment from "moment";
import { useState } from "react";
import {
  GetTopLevelGoalsQueryQuery,
  GetTopLevelGoalsQueryQueryVariables,
  GoalScope,
  GoalState,
} from "types/graphql-schema";
import { DateRangeEnum } from "types/topicflow";

import GoalPageHeader from "@apps/goal-overview/header";
import useLabel from "@apps/use-label/use-label";
import useUiPreferenceCache from "@apps/use-ui-preference-cache/use-ui-preference-cache";
import { currentOrganizationVar } from "@cache/cache";
import Loading from "@components/loading/loading";
import { Select, SelectOption } from "@components/select/select";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { assertEdgesNonNull, dateRangeToDateArray } from "@helpers/helpers";

import GoalAlignmentTree from "./goal-alignment-tree";
import getTopLevelGoalsQuery from "./graphql/get-top-level-goals-query";

export enum AllDateRangeEnum {
  all = "all",
}

type GoalAlignmentDateRangeType = AllDateRangeEnum | DateRangeEnum;

const GoalAlignment = () => {
  const label = useLabel();
  const currentOrganization = currentOrganizationVar();
  const { uiPreferenceCache, saveUiPreference } = useUiPreferenceCache();
  const [selectedDateRange, setSelectedDateRange] =
    useState<GoalAlignmentDateRangeType>(DateRangeEnum.thisQuarter);

  const variables = {
    goalDueBetweenDates:
      selectedDateRange === AllDateRangeEnum.all
        ? undefined
        : dateRangeToDateArray({
            range: selectedDateRange,
            quarterStartMonth: currentOrganization.quarterStartMonth,
          }),
    goalStates: uiPreferenceCache.objectiveAlignmentIsShowingClosed
      ? null
      : [GoalState.Draft, GoalState.Open],
    goalScopes: uiPreferenceCache.objectiveAlignmentIsShowingUnaligned
      ? [GoalScope.Organization, GoalScope.Team, GoalScope.Personal]
      : [GoalScope.Organization],
  };
  const { data, loading } = useQuery<
    GetTopLevelGoalsQueryQuery,
    GetTopLevelGoalsQueryQueryVariables
  >(getTopLevelGoalsQuery, {
    variables,
    onError: onNotificationErrorHandler(),
  });
  const goals = data?.artifacts ? assertEdgesNonNull(data.artifacts) : [];

  const dateRangeOptions = Object.values(DateRangeEnum).map((dateRange) => ({
    value: dateRange,
    label: label(dateRange, { capitalize: true }),
    selected: selectedDateRange === dateRange,
    description: dateRangeToDateArray({
      range: dateRange,
      quarterStartMonth: currentOrganization.quarterStartMonth,
    })
      .map((date) => moment(date).format("ll"))
      .join(" - "),
  }));
  const allDateRangeOptions = [
    {
      value: AllDateRangeEnum.all,
      label: "Anytime",
      selected: selectedDateRange === null,
    },
    ...dateRangeOptions,
  ];

  const handleChangeDateRange = (
    option: SelectOption<GoalAlignmentDateRangeType>
  ) => {
    setSelectedDateRange(option.value);
  };

  const orgGoals = goals.filter(
    (artifact) =>
      artifact.__typename === "GoalArtifactNode" &&
      artifact.scope === GoalScope.Organization
  );

  const unalignedGoals = goals.filter(
    (artifact) =>
      artifact.__typename === "GoalArtifactNode" &&
      artifact.scope !== GoalScope.Organization &&
      !artifact.parentGoal
  );

  return (
    <div aria-label="Goal alignment" className="flex flex-col flex-1 fs-unmask">
      <GoalPageHeader />
      <div className="p-4 sm:p-6 flex flex-col gap-8">
        <div className="flex items-center gap-8 text-sm flex-wrap">
          <Select<GoalAlignmentDateRangeType>
            options={allDateRangeOptions}
            value={selectedDateRange}
            onChange={handleChangeDateRange}
          />
          <label className="flex items-center gap-1.5 tracking-tight shrink-0">
            <input
              type="checkbox"
              checked={uiPreferenceCache.objectiveAlignmentIsShowingKeyResults}
              onChange={(e) =>
                saveUiPreference({
                  objectiveAlignmentIsShowingKeyResults: e.target.checked,
                })
              }
            />
            Show {label("key result", { pluralize: true })}
          </label>
          <label className="flex items-center gap-1.5 tracking-tight shrink-0">
            <input
              type="checkbox"
              checked={uiPreferenceCache.objectiveAlignmentIsShowingClosed}
              onChange={(e) =>
                saveUiPreference({
                  objectiveAlignmentIsShowingClosed: e.target.checked,
                })
              }
            />
            Show closed {label("goal", { pluralize: true })}
          </label>
          {loading && data && <Loading mini size={5} />}
        </div>
        {!data && loading && (
          <Loading>Loading {label("goal", { pluralize: true })}</Loading>
        )}

        {orgGoals.length === 0 && unalignedGoals.length === 0 && (
          <div className="text-sm text-gray-500">
            No {label("goal", { pluralize: true })}.
          </div>
        )}

        {orgGoals.length > 0 && (
          <div>
            <div className="text-xl font-medium mb-4">
              Aligned {label("goal", { pluralize: true, capitalize: true })}
            </div>
            <div className="-ml-1">
              <GoalAlignmentTree
                goals={orgGoals}
                isShowingKeyResults={
                  uiPreferenceCache.objectiveAlignmentIsShowingKeyResults
                }
                goalDueBetweenDates={variables.goalDueBetweenDates}
                goalStates={variables.goalStates}
                indent={0}
              />
            </div>
          </div>
        )}

        {unalignedGoals.length > 0 && (
          <div className="">
            <div className="text-xl font-medium mb-4">
              Unaligned {label("goal", { pluralize: true, capitalize: true })}
            </div>
            <div className="-ml-1">
              <GoalAlignmentTree
                goals={unalignedGoals}
                isShowingKeyResults={
                  uiPreferenceCache.objectiveAlignmentIsShowingKeyResults
                }
                goalDueBetweenDates={variables.goalDueBetweenDates}
                goalStates={variables.goalStates}
                indent={0}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default GoalAlignment;
