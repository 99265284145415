import { useQuery } from "@apollo/client";
import { ChevronDownIcon, ChevronRightIcon } from "@heroicons/react/outline";
import { pull, union } from "lodash";
import {
  AlignmentGoalFragmentFragment,
  GetAlignedChildGoalsQueryQuery,
  GetAlignedChildGoalsQueryQueryVariables,
  GetTopLevelGoalsQueryQueryVariables,
} from "types/graphql-schema";

import GoalKeyResultItem from "@apps/artifact-sidebar/components/goal-key-result-item";
import Artifact from "@apps/artifact/artifact";
import ArtifactDropdownMenu from "@apps/artifact/artifact-dropdown-menu";
import { GoalProgressPillView } from "@apps/goal-progress-pill-with-popover/goal-progress-pill-with-popover";
import useUiPreferenceCache from "@apps/use-ui-preference-cache/use-ui-preference-cache";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { batchClient } from "@graphql/client";
import { classNames } from "@helpers/css";
import { assertEdgesNonNull } from "@helpers/helpers";

import getAlignedChildGoalsQuery from "./graphql/get-aligned-child-goals-query";

const indentWidth = 20;

const GoalAlignmentTreeGoal = ({
  goal,
  isShowingKeyResults,
  goalDueBetweenDates,
  goalStates,
  indent,
}: {
  indent: number;
  goal: AlignmentGoalFragmentFragment;
  isShowingKeyResults: boolean;
  goalDueBetweenDates: GetTopLevelGoalsQueryQueryVariables["goalDueBetweenDates"];
  goalStates: GetTopLevelGoalsQueryQueryVariables["goalStates"];
}) => {
  const { uiPreferenceCache, saveUiPreference } = useUiPreferenceCache();
  const isExpanded = (
    uiPreferenceCache.objectiveAlignmentExpandedIds || []
  ).includes(goal.id);

  const { data } = useQuery<
    GetAlignedChildGoalsQueryQuery,
    GetAlignedChildGoalsQueryQueryVariables
  >(getAlignedChildGoalsQuery, {
    variables: {
      goalId: goal.id,
      goalDueBetweenDates,
      goalStates,
    },
    skip: !isExpanded,
    client: batchClient,
    onError: onNotificationErrorHandler(),
  });
  if (goal.__typename !== "GoalArtifactNode") return null;
  const childGoals = (
    data?.artifact && data.artifact.__typename === "GoalArtifactNode"
      ? assertEdgesNonNull(data.artifact.childGoals)
      : []
  ) as AlignmentGoalFragmentFragment[];

  const handleToggleChildGoals = () => {
    const objectiveAlignmentExpandedIds = isExpanded
      ? pull(uiPreferenceCache.objectiveAlignmentExpandedIds, goal.id)
      : union(uiPreferenceCache.objectiveAlignmentExpandedIds, [goal.id]);
    saveUiPreference({ objectiveAlignmentExpandedIds });
  };

  const childGoalIds = assertEdgesNonNull(goal.childGoals).map(({ id }) => id);
  return (
    <div key={goal.id} className="">
      <div
        key={goal.id}
        className="p-1 rounded-md group hover:bg-black/5 flex items-start gap-1"
        style={{ paddingLeft: `${indent * indentWidth + 4}px` }}
      >
        <button
          className={classNames(
            "mt-[8px] p-1 rounded hover:bg-black/5 shrink-0 text-gray-500",
            childGoalIds.length === 0 && "opacity-0"
          )}
          onClick={handleToggleChildGoals}
        >
          {isExpanded ? (
            <ChevronDownIcon className="h-3 w-3" />
          ) : (
            <ChevronRightIcon className="h-3 w-3" />
          )}
        </button>
        <div
          key={goal.id}
          className={classNames(
            "flex-1 rounded-md bg-white border px-2 py-2",
            isShowingKeyResults && goal.keyResults.edges.length > 0 && "pb-2"
          )}
        >
          <Artifact
            artifact={goal}
            showTitleOnlyOnOneLine
            hideActionDropdown
            goalPillView={GoalProgressPillView.pillAndProgressBar}
          />
          {isShowingKeyResults && goal.keyResults.edges.length > 0 && (
            <div className="mt-2 text-sm text-gray-700">
              {assertEdgesNonNull(goal.keyResults).map((keyResult) => (
                <GoalKeyResultItem
                  key={keyResult.id}
                  className="py-0.5 pl-0 pr-0 gap-2.5"
                  goalArtifact={goal}
                  keyResult={keyResult}
                  isReadOnly
                />
              ))}
            </div>
          )}
        </div>
        <div className="flex items-center opacity-0 group-hover:opacity-100">
          <ArtifactDropdownMenu
            artifact={goal}
            size="5"
            portal={true}
            className="hover:bg-black/5 p-0.5"
          />
        </div>
      </div>
      {isExpanded && childGoals.length > 0 && (
        <div className="relative">
          {isExpanded && childGoals.length > 0 && (
            <div
              className="w-px absolute top-0 bottom-0 left-3.5 bg-black/5 -ml-px"
              style={{ marginLeft: `${(indent + 1) * indentWidth}px` }}
            />
          )}
          <GoalAlignmentTree
            goals={childGoals}
            isShowingKeyResults={isShowingKeyResults}
            goalDueBetweenDates={goalDueBetweenDates}
            goalStates={goalStates}
            indent={indent + 1}
          />
        </div>
      )}
    </div>
  );
};

const GoalAlignmentTree = ({
  goals,
  isShowingKeyResults,
  goalDueBetweenDates,
  goalStates,
  indent,
}: {
  indent: number;
  goals: AlignmentGoalFragmentFragment[];
  isShowingKeyResults: boolean;
  goalDueBetweenDates: GetTopLevelGoalsQueryQueryVariables["goalDueBetweenDates"];
  goalStates: GetTopLevelGoalsQueryQueryVariables["goalStates"];
}) => {
  return (
    <div className="">
      {goals.map((goal) =>
        goal.__typename === "GoalArtifactNode" ? (
          <GoalAlignmentTreeGoal
            key={goal.id}
            goal={goal}
            isShowingKeyResults={isShowingKeyResults}
            goalDueBetweenDates={goalDueBetweenDates}
            goalStates={goalStates}
            indent={indent}
          />
        ) : null
      )}
    </div>
  );
};

export default GoalAlignmentTree;
