import { useQuery } from "@apollo/client";
import { useLocation } from "react-router-dom";
import {
  CommentNode,
  GetWysiwygArtifactQueryQuery,
  GetWysiwygArtifactQueryQueryVariables,
  GoalArtifactNode,
} from "types/graphql-schema";
import { TFLocationState } from "types/topicflow";

import GoalKeyResultItemReadOnly from "@apps/artifact-sidebar/components/goal-key-result-item";
import useLabel from "@apps/use-label/use-label";
import AppLink from "@components/link/link";
import TimeAgoCustom from "@components/time-ago/time-ago";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import CommentWYSIWYG from "@components/wysiwyg/comment-wysiwyg";
import {
  assertEdgesNonNull,
  getUrl,
  parseStringToJSON,
  toWithBackground,
} from "@helpers/helpers";

import getWysiwygArtifactQuery from "../graphql/get-wysiwyg-artifact-query";

const ArtifactComponentGoalKeyResults = ({
  artifactId,
}: {
  artifactId: number;
}) => {
  const label = useLabel();
  const location = useLocation<TFLocationState>();
  const { data } = useQuery<
    GetWysiwygArtifactQueryQuery,
    GetWysiwygArtifactQueryQueryVariables
  >(getWysiwygArtifactQuery, {
    fetchPolicy: "cache-only", // rely only on cached content to render this view
    variables: { artifactId: Number(artifactId) },
    onError: onNotificationErrorHandler(),
  });

  const goal = data?.artifact as GoalArtifactNode;
  const keyResults = goal?.keyResults
    ? assertEdgesNonNull(goal.keyResults)
    : [];
  const activities = goal ? assertEdgesNonNull(goal.activities) : [];
  const lastCheckin = activities[0];
  const commentChangedField = lastCheckin?.changedFields?.find(
    (changedField) => changedField?.fieldName === "comment"
  );
  const comment = (commentChangedField?.newValueObject as CommentNode) || null;

  if (keyResults.length > 0) {
    return (
      <div className="-ml-3 -mr-2 border-t divide-y not-prose">
        {keyResults.map((keyResult) => (
          <div key={keyResult.id} className="pl-3 pr-2">
            <GoalKeyResultItemReadOnly
              goalArtifact={goal}
              keyResult={keyResult}
              isReadOnly
            />
          </div>
        ))}
        {lastCheckin && comment && (
          <div className="pl-3 pr-2 py-3 text-sm">
            <div>
              <AppLink
                to={getUrl({ userId: lastCheckin.actor?.id })}
                className="font-medium hover:underline"
              >
                {lastCheckin.actor?.name || "A user"}
              </AppLink>{" "}
              updated this {label("goal")}{" "}
              <AppLink
                to={toWithBackground({
                  pathname: `${getUrl({
                    artifactId: goal.id,
                    artifactType: goal.artifactType,
                  })}?activityId=${lastCheckin.id}`,
                  location,
                })}
                className="whitespace-nowrap text-xs tracking-tighter ml-1 text-gray-400 hover:underline"
              >
                <TimeAgoCustom date={lastCheckin.created} />
              </AppLink>
            </div>
            <CommentWYSIWYG
              key={comment.comment} // force re-rendering
              mentionsConfig={{
                artifactId: goal.id,
              }}
              value={parseStringToJSON(comment.comment)}
              className="mt-1"
              editable={false}
              uploadVariable={{
                artifactId: goal.id,
              }}
            />
          </div>
        )}
      </div>
    );
  }
  return null;
};

export default ArtifactComponentGoalKeyResults;
