import { Dialog } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { useEffect, useState } from "react";
import { MeetingViewMeetingNodeNewPageFragmentFragment } from "types/graphql-schema";

import MeetingViewSidebar from "@apps/meeting-new/components/meeting-sidebar/meeting-view-sidebar";
import Topic from "@apps/meeting-new/components/topic";
import { MeetingWebsocketProviderContext } from "@apps/meeting-new/context";
import useMeetingWebsocketProvider from "@apps/meeting-new/use-meeting-websocket-provider";
import Layout from "@components/layout/layout";
import useWindowSize from "@components/use-window-size/use-window-size";
import { assertEdgesNonNull } from "@helpers/helpers";

export const meetingDialogInputClassName =
  "hover:bg-gray-100 rounded text-sm px-2 py-1.5 focus:outline-0 focus:ring-0";

const MeetingPresentationDialog = ({
  onClose,
  meeting,
}: {
  onClose: () => void;
  meeting: MeetingViewMeetingNodeNewPageFragmentFragment;
}) => {
  const { height } = useWindowSize();

  const [currentTopicId, setCurrentTopicId] = useState<undefined | number>();
  const topics = assertEdgesNonNull(meeting.topics);
  useEffect(() => {
    if (!currentTopicId && topics.length > 0) {
      setCurrentTopicId(topics[0].id);
    }
  }, [currentTopicId, setCurrentTopicId, topics]);

  const handleClickTopic = ({ id }: { id: number }) => {
    const el = document.getElementById(`meeting-presentation-topic-${id}`);
    if (el) {
      el.scrollIntoView({ behavior: "smooth" });
    }
  };

  const meetingWebsocketContext = useMeetingWebsocketProvider(meeting);

  return (
    <Dialog
      as="div"
      aria-label="Meeting presentation dialog"
      className="fixed z-presentation inset-0 bg-white"
      open
      onClose={() => {}}
    >
      <Layout>
        <Layout.Header
          sidebarExpandedUiPreferenceKey="meetingSidebarExpanded"
          title={meeting.title}
        >
          <button
            className={Layout.headerIconButtonClassName}
            onClick={onClose}
          >
            <XIcon className="h-5 w-5" />
          </button>
        </Layout.Header>
        <Layout.Container className="flex-col">
          <Layout.Main sidebarExpandedUiPreferenceKey="meetingSidebarExpanded">
            {topics.length > 0 ? (
              <ul className="min-h-0 px-4 overflow-y-auto list-none divide-y">
                <MeetingWebsocketProviderContext.Provider
                  value={meetingWebsocketContext}
                >
                  {topics.map((topic) => (
                    <div
                      id={`meeting-presentation-topic-${topic.id}`}
                      key={topic.id}
                      className="pt-6 pb-18"
                      style={{
                        minHeight: height
                          ? `${Math.max(100, height - 120)}px`
                          : "min-h-full",
                      }}
                    >
                      <Topic meeting={meeting} topic={topic} isInPresentation />
                    </div>
                  ))}
                </MeetingWebsocketProviderContext.Provider>
              </ul>
            ) : (
              <div>No topics.</div>
            )}
          </Layout.Main>

          <MeetingViewSidebar
            meeting={meeting}
            isInFullScreen
            selectedTopicId={currentTopicId}
            onClickTopic={handleClickTopic}
          />
        </Layout.Container>
      </Layout>
    </Dialog>
  );
};

export default MeetingPresentationDialog;
