import { useQuery } from "@apollo/client";
import { Popover } from "@headlessui/react";
import {
  ArrowSmRightIcon,
  ExternalLinkIcon,
  SelectorIcon,
  XIcon,
} from "@heroicons/react/outline";
import { compact, orderBy, uniqBy } from "lodash";
import moment from "moment";
import numeral from "numeral";
import { ChangeEvent, PropsWithChildren, useCallback, useState } from "react";
import DatePicker from "react-datepicker";
import {
  GetKpisToConnectToKrQueryQuery,
  GetKpisToConnectToKrQueryQueryVariables,
  GoalProgressType,
  GoalScope,
  GoalState,
  GoalVisibility,
} from "types/graphql-schema";
import { BasicUser } from "types/topicflow";
import { v4 as uuidv4 } from "uuid";

import ArtifactSidebarGoalPickerCombobox from "@apps/artifact-sidebar/aligned-goal-picker-combobox";
import getKpisToConnectToKrQuery from "@apps/artifact-sidebar/graphql/get-kpis-to-connect-to-kr-query";
import ArtifactTeamsInput, {
  ArtifactTeamType,
} from "@apps/artifact/components/artifact-teams-input";
import { GoalScopeDropdownInput } from "@apps/artifact/components/goal-scope";
import { GoalVisibilityDropdownInput } from "@apps/artifact/components/goal-visibility";
import useLabel from "@apps/use-label/use-label";
import Button, { buttonTheme } from "@components/button/button";
import ComboboxGeneric, {
  ComboboxGenericOption,
} from "@components/combobox/generic-combobox";
import DatePickerCustomHeaderWithClearButton from "@components/datepicker/custom-header-with-clear-button";
import Input from "@components/input/input";
import GoalOwnerContributorDropdown from "@components/people-dropdown/goal-owner-contributor-dropdown";
import KeyResultAssigneeDropdown from "@components/people-dropdown/key-result-assignee-dropdown";
import Select, { SelectOption } from "@components/select/select";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { UserComboboxUserOption } from "@components/user-combobox/user-combobox-list";
import {
  getKeyResultProgressTypeOptions,
  getProgressTypeOptions,
  graphqlNone,
} from "@helpers/constants";
import {
  classNames,
  inputBorderClassName,
  inputFocusClassName,
} from "@helpers/css";
import { assertEdgesNonNull } from "@helpers/helpers";

import { ArtifactCreationDialogFormType } from "./artifact-creation-dialog";

type DialogLabelProps = {
  className?: string;
};
const DialogLabel: React.FC<PropsWithChildren<DialogLabelProps>> = ({
  children,
  className = "",
}) => (
  <label
    className={classNames("text-gray-500 text-sm w-24 font-medium", className)}
  >
    {children}
  </label>
);

type KpiPickerProps = {
  loading: boolean;
  value: ComboboxGenericOption<number>;
  options: ComboboxGenericOption<number>[];
  onSelectKpi: (kpi: ComboboxGenericOption<number>) => void;
};
export const KpiPicker: React.FC<KpiPickerProps> = ({
  loading,
  value,
  options,
  onSelectKpi,
}) => {
  const filterKpiOptions = uniqBy(
    orderBy(
      options,
      [({ value }) => value < 0, ({ label }) => label.toLowerCase()],
      ["desc", "asc"]
    ),
    "value"
  );
  return (
    <ComboboxGeneric
      aria-label="KPI picker"
      loading={loading}
      width="64"
      value={value}
      options={filterKpiOptions}
      clearable={false}
      onChangeValue={onSelectKpi}
    >
      {({ value, setReferenceElement }) => (
        <div
          className={classNames(
            "flex justify-between bg-white rounded-md",
            inputBorderClassName,
            inputFocusClassName
          )}
        >
          <Popover.Button
            className="px-2 py-0.5 flex gap-2 text-sm text-left items-center min-w-0 max-w-72"
            ref={setReferenceElement}
            aria-label="Kpi picker button"
          >
            <div className="flex-1 truncate">{value?.label}</div>
            <span>
              <SelectorIcon className="h-4 w-4" />
            </span>
          </Popover.Button>
        </div>
      )}
    </ComboboxGeneric>
  );
};

const getEmptyKr = () => ({
  uuid: uuidv4(),
  title: "",
  startValue: 0,
  targetValue: 100,
  assignee: undefined,
  progressType: GoalProgressType.Percentage,
});

const noKpiOption = { value: graphqlNone, label: "None" };

const ArtifactCreationGoalFields = ({
  form,
  onChangeForm,
}: {
  form: ArtifactCreationDialogFormType;
  onChangeForm: (form: ArtifactCreationDialogFormType) => void;
}) => {
  const label = useLabel();
  const [isShowingOwnersContributors, setIsShowingOwnersContributors] =
    useState(false);

  const { data, loading: kpiLoading } = useQuery<
    GetKpisToConnectToKrQueryQuery,
    GetKpisToConnectToKrQueryQueryVariables
  >(getKpisToConnectToKrQuery, {
    onError: onNotificationErrorHandler(),
  });

  const defaultKpiOptions = compact([
    noKpiOption,
    form.kpi?.id && {
      value: form.kpi.id,
      label: form.kpi.title,
    },
  ]);
  const kpis = data?.kpis ? assertEdgesNonNull(data.kpis) : [];
  const kpiOptions = defaultKpiOptions.concat(
    kpis.map(({ id, title, currentMeasurement }) => ({
      value: id,
      label: title,
      currentMeasurement,
    }))
  );

  const handleChangeDueDate = (updatedDate: Date | null) => {
    const dueDate = updatedDate
      ? moment(updatedDate).format("YYYY-MM-DD")
      : null;
    onChangeForm({ ...form, dueDate });
  };

  const handleChangeStartDate = (updatedDate: Date | null) => {
    const startDate = updatedDate
      ? moment(updatedDate).format("YYYY-MM-DD")
      : null;
    onChangeForm({ ...form, startDate });
  };

  const handleChangeGoalOwners = (owners: BasicUser[]) => {
    onChangeForm({ ...form, owners });
  };

  const handleChangeGoalContributors = (contributors: BasicUser[]) => {
    onChangeForm({ ...form, contributors });
  };

  const handleChangeGoalScope = ({ value: scope }: { value: GoalScope }) => {
    if (scope === GoalScope.Career) {
      onChangeForm({ ...form, scope, goalVisibility: GoalVisibility.Private });
    } else {
      onChangeForm({ ...form, scope });
    }
  };

  const handleChangeGoalVisibility = (option: SelectOption<GoalVisibility>) => {
    onChangeForm({ ...form, goalVisibility: option.value });
  };

  const handleChangeStartValue = (e: ChangeEvent<HTMLInputElement>) => {
    onChangeForm({ ...form, startValue: e.target.value });
  };

  const handleChangeTargetValue = (e: ChangeEvent<HTMLInputElement>) => {
    onChangeForm({ ...form, targetValue: e.target.value });
  };

  const handleChangeGoalProgressType = (option: {
    value: GoalProgressType;
  }) => {
    // reset kpi value if changing to average type
    const kpi =
      option.value === GoalProgressType.AlignedAverage ? undefined : form.kpi;
    onChangeForm({
      ...form,
      progressType: option.value,
      kpi,
    });
  };

  const handleSelectGoalKpi = useCallback(
    (option: ComboboxGenericOption<number>) => {
      onChangeForm({
        ...form,
        kpi:
          option.value === graphqlNone
            ? undefined
            : { ...option, id: option.value, title: option.label },
      });
    },
    [form, onChangeForm]
  );

  const handleClickAddKR = () => {
    onChangeForm({
      ...form,
      keyResults: [...form.keyResults, getEmptyKr()],
    });
  };

  const handleClickRemoveKR = (uuid: string) => {
    onChangeForm({
      ...form,
      keyResults: form.keyResults.filter((kr) => kr.uuid !== uuid) || [],
    });
  };

  const handleChangeKrValue = (
    uuid: string,
    key: string,
    e: ChangeEvent<HTMLInputElement>
  ) => {
    const keyResults = form.keyResults.map((kr) => {
      if (kr.uuid === uuid) return { ...kr, [`${key}`]: e.target.value };
      return kr;
    });
    onChangeForm({ ...form, keyResults });
  };

  const handleChangeKrProgressType =
    (uuid: string) => (option: SelectOption<GoalProgressType>) => {
      const keyResults = form.keyResults.map((kr) => {
        if (kr.uuid === uuid) return { ...kr, progressType: option.value };
        return kr;
      });
      onChangeForm({ ...form, keyResults });
    };

  const handleChangeKrAssignee = (
    uuid: string,
    newAssignee: UserComboboxUserOption
  ) => {
    const keyResults = form.keyResults.map((kr) => {
      if (kr.uuid === uuid)
        return { ...kr, assignee: newAssignee.id ? newAssignee : undefined };
      return kr;
    });
    onChangeForm({ ...form, keyResults });
  };

  const handleChangeKrKpi = (
    uuid: string,
    option: ComboboxGenericOption<number>
  ) => {
    const keyResults = form.keyResults.map((kr) => {
      if (kr.uuid === uuid)
        return {
          ...kr,
          kpi:
            option.value === graphqlNone
              ? undefined
              : { ...option, id: option.value, title: option.label },
        };
      return kr;
    });
    onChangeForm({ ...form, keyResults });
  };

  const handleAddTeam = (team: ArtifactTeamType) => {
    const teams = uniqBy([...form.teams, team], "id");
    onChangeForm({ ...form, teams });
  };

  const handleRemoveTeam = (id: number) => {
    const teams = form.teams.filter((team) => team.id !== id);
    onChangeForm({ ...form, teams });
  };

  const handleSelectChildGoal = (option: ComboboxGenericOption<number>) => {
    const childGoals = [...form.childGoals, option];
    onChangeForm({ ...form, childGoals });
  };

  const handleClearChildGoal = (option: ComboboxGenericOption<number>) => {
    const childGoals = form.childGoals.filter(
      ({ value }) => value !== option.value
    );
    onChangeForm({ ...form, childGoals });
  };

  const handleSelectParentGoal = (option: ComboboxGenericOption<number>) => {
    onChangeForm({ ...form, parentGoal: option });
  };

  const handleClearParentGoal = () => {
    onChangeForm({ ...form, parentGoal: undefined });
  };

  const invalidGoalDate =
    form.startDate &&
    form.dueDate &&
    moment(form.startDate).isAfter(form.dueDate);
  const involvedUserIds = [
    ...(form.owners || []),
    ...(form.contributors || []),
  ].map(({ id }) => id);

  return (
    <>
      <div className="flex items-center gap-4 py-4 px-6">
        <DialogLabel>Date</DialogLabel>
        <div className="flex-1 block">
          <div className="flex gap-2 items-center">
            <DatePicker
              selected={form.startDate ? moment(form.startDate).toDate() : null}
              placeholderText="Start date"
              onChange={handleChangeStartDate}
              dateFormat="MMM d, yyyy"
              className="text-sm w-32 px-3 py-1.5 outline-0 border bg-transparent rounded-lg"
              renderCustomHeader={({
                date: currentDate,
                decreaseMonth,
                increaseMonth,
                prevMonthButtonDisabled,
                nextMonthButtonDisabled,
              }) => (
                <DatePickerCustomHeaderWithClearButton
                  date={form.startDate ? moment(form.startDate).toDate() : null}
                  onChangeDate={handleChangeStartDate}
                  clearable
                  currentDate={currentDate}
                  decreaseMonth={decreaseMonth}
                  increaseMonth={increaseMonth}
                  prevMonthButtonDisabled={prevMonthButtonDisabled}
                  nextMonthButtonDisabled={nextMonthButtonDisabled}
                />
              )}
            />
            <ArrowSmRightIcon className="text-gray-500 w-4 h-4" />
            <DatePicker
              selected={form.dueDate ? moment(form.dueDate).toDate() : null}
              placeholderText="Due date"
              onChange={handleChangeDueDate}
              dateFormat="MMM d, yyyy"
              className="text-sm w-32 px-3 py-1.5 outline-0 border bg-transparent rounded-lg"
              renderCustomHeader={({
                date: currentDate,
                decreaseMonth,
                increaseMonth,
                prevMonthButtonDisabled,
                nextMonthButtonDisabled,
              }) => (
                <DatePickerCustomHeaderWithClearButton
                  date={form.dueDate ? moment(form.dueDate).toDate() : null}
                  onChangeDate={handleChangeDueDate}
                  clearable
                  currentDate={currentDate}
                  decreaseMonth={decreaseMonth}
                  increaseMonth={increaseMonth}
                  prevMonthButtonDisabled={prevMonthButtonDisabled}
                  nextMonthButtonDisabled={nextMonthButtonDisabled}
                />
              )}
            />
          </div>
          {invalidGoalDate && (
            <div className="text-sm mt-1 text-red-700">
              The start date cannot be after the due date.
            </div>
          )}
        </div>
      </div>
      <div className="flex items-start gap-4 py-4 px-6">
        <DialogLabel className="mt-0.5">Owners</DialogLabel>
        <div className="flex-1 flex flex-col gap-2 text-sm">
          <div className="flex-1 flex gap-4 items-center">
            <div className="flex flex-col gap-2">
              <GoalOwnerContributorDropdown
                isSettingMultipleUsers={isShowingOwnersContributors}
                users={form.owners || []}
                excludeUserIds={involvedUserIds}
                onChangeUsers={handleChangeGoalOwners}
                placeholder="Add an owner"
                mustHaveOneUserMinimum
              />
            </div>
            {!isShowingOwnersContributors && (
              <button
                onClick={() => setIsShowingOwnersContributors(true)}
                className="text-xs tracking-tight mt-px text-gray-400 hover:underline hover:text-gray-700"
              >
                Add more owners & contributors
              </button>
            )}
          </div>
        </div>
      </div>
      {isShowingOwnersContributors && (
        <div className="flex items-start gap-4 py-4 px-6">
          <DialogLabel className="mt-0.5">Contributors</DialogLabel>
          <div className="flex-1 flex flex-col gap-2 text-sm">
            <div className="flex-1 flex gap-4 items-center">
              <div className="flex flex-col gap-2">
                <GoalOwnerContributorDropdown
                  isSettingMultipleUsers
                  users={form.contributors || []}
                  excludeUserIds={involvedUserIds}
                  onChangeUsers={handleChangeGoalContributors}
                  placeholder="Add a contributor"
                />
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="flex items-center gap-4 py-4 px-6">
        <DialogLabel>Type</DialogLabel>
        <div className="flex-1 w-full flex flex-col gap-2 -ml-2">
          <GoalScopeDropdownInput
            artifact={{
              ...form,
              scope: form.scope || GoalScope.Personal,
              state: GoalState.Open,
              canUpdate: { permission: true },
            }}
            onChangeScope={handleChangeGoalScope}
            iconSize={4}
          />
          {form.scope === GoalScope.Team && (
            <div className="pl-2">
              <ArtifactTeamsInput
                teams={form.teams}
                onAddTeam={handleAddTeam}
                onRemoveTeam={handleRemoveTeam}
                canEdit
              />
            </div>
          )}
        </div>
      </div>
      <div className="flex items-center gap-4 py-4 px-6">
        <DialogLabel>Visibility</DialogLabel>
        <div className="flex-1 flex -ml-2">
          <GoalVisibilityDropdownInput
            artifact={{
              ...form,
              canUpdate: { permission: true },
              goalVisibility: form.goalVisibility || GoalVisibility.Public,
            }}
            onChangeVisibility={handleChangeGoalVisibility}
            iconSize="4"
          />
        </div>
      </div>
      <div className="flex items-start gap-4 py-4 px-6">
        <DialogLabel className="mt-0.5">Progress</DialogLabel>
        <div className="flex-1 flex flex-col gap-3">
          {form.progressType !== GoalProgressType.AlignedAverage &&
            form.progressType !== GoalProgressType.Boolean && (
              <div className="flex-1 flex gap-4 items-center justify-between flex-wrap max-w-92">
                <div className="flex gap-1.5 items-center">
                  <div className="text-sm text-gray-500 tracking-tight w-18">
                    Start value
                  </div>
                  <Input
                    type="text"
                    className="w-22 text-sm px-2 py-0.5"
                    aria-label="Goal start value input"
                    value={form.startValue}
                    onChange={handleChangeStartValue}
                  />
                </div>
                <div className="flex gap-1.5 items-center">
                  <div className="text-sm text-gray-500 tracking-tight">
                    Target value
                  </div>
                  <Input
                    type="text"
                    className="w-22 text-sm px-2 py-0.5 border"
                    aria-label="Goal target value input"
                    value={form.targetValue}
                    onChange={handleChangeTargetValue}
                  />
                </div>
              </div>
            )}
          <div className="flex items-start gap-1.5">
            <div className="text-sm text-gray-500 tracking-tight w-18 mt-0.5">
              Type
            </div>
            <div>
              <div className="flex items-center gap-1.5">
                <Select
                  className="text-sm pl-2 py-0.5"
                  onChange={handleChangeGoalProgressType}
                  value={form.progressType}
                  options={getProgressTypeOptions()}
                />
              </div>
            </div>
          </div>
          {form.progressType !== GoalProgressType.AlignedAverage &&
            kpiOptions.length > 1 && (
              <div className="flex items-start gap-1.5">
                <div className="text-sm text-gray-500 tracking-tight w-18 mt-0.5">
                  Linked KPI
                </div>
                <div>
                  <div className="flex items-center gap-1.5">
                    <KpiPicker
                      loading={kpiLoading}
                      options={kpiOptions}
                      value={
                        form.kpi
                          ? { value: form.kpi.id, label: form.kpi.title }
                          : noKpiOption
                      }
                      onSelectKpi={handleSelectGoalKpi}
                    />
                    {form.kpi && form.kpi?.id !== noKpiOption.value && (
                      <a
                        href={`/kpis/${form.kpi.id}`}
                        rel="noreferrer"
                        target="_blank"
                        className="p-1 hover:bg-gray-100 rounded text-gray-500 hover:text-gray-800"
                      >
                        <ExternalLinkIcon className="h-4 w-4" />
                      </a>
                    )}
                  </div>
                  {form.kpi &&
                    form.kpi?.id !== noKpiOption.value &&
                    form.kpi.currentMeasurement && (
                      <a
                        href={`/kpis/${form.kpi.id}`}
                        rel="noreferrer"
                        target="_blank"
                        className="mt-0.5 text-xs tracking-tight text-gray-500 hover:underline"
                      >
                        Current value:{" "}
                        {numeral(
                          form.kpi.currentMeasurement.measurement
                        ).format()}
                      </a>
                    )}
                </div>
              </div>
            )}
        </div>
      </div>
      <div className="flex items-start gap-4 py-4 px-6">
        <DialogLabel className="mt-0.5">
          {label("key result", { pluralize: true, capitalize: true })}
        </DialogLabel>
        <div className="flex-1 flex flex-col">
          {form.keyResults.map((keyResult, i) => (
            <div
              key={keyResult.uuid}
              className={classNames(
                "flex gap-4 border-b border-gray-100 pb-2",
                i > 0 && "pt-2"
              )}
            >
              <div className="flex-1 flex flex-col gap-3">
                <div className="flex gap-1.5 items-center">
                  <div className="flex-1 flex gap-1.5 items-center max-w-92">
                    <div className="text-sm text-gray-500 tracking-tight w-18">
                      Title
                    </div>
                    <Input
                      type="text"
                      className="flex-1 min-w-0 text-sm px-2 py-0.5"
                      aria-label="Key result title input"
                      value={keyResult.title}
                      placeholder={`Type ${label("key result")} title...`}
                      onChange={(e) =>
                        handleChangeKrValue(keyResult.uuid, "title", e)
                      }
                    />
                  </div>
                  <div className="flex items-start">
                    <div className="flex items-center gap-2">
                      <KeyResultAssigneeDropdown
                        className="px-0.5"
                        keyResult={keyResult}
                        onChangeAssignee={(option) =>
                          handleChangeKrAssignee(keyResult.uuid, option)
                        }
                        canChange
                      />
                      <Button
                        theme={buttonTheme.iconGray}
                        icon
                        onClick={() => handleClickRemoveKR(keyResult.uuid)}
                      >
                        <XIcon className="h-5 w-5" />
                      </Button>
                    </div>
                  </div>
                </div>
                {keyResult.progressType !== GoalProgressType.Boolean && (
                  <div className="flex gap-4 items-center justify-between flex-wrap max-w-92 ">
                    <div className="flex gap-1.5 items-center">
                      <div className="text-sm text-gray-500 tracking-tight w-18">
                        Start value
                      </div>
                      <Input
                        type="text"
                        className="w-22 text-sm px-2 py-0.5"
                        aria-label="Key result start value input"
                        value={keyResult.startValue}
                        onChange={(e) =>
                          handleChangeKrValue(keyResult.uuid, "startValue", e)
                        }
                      />
                    </div>
                    <div className="flex gap-1.5 items-center">
                      <div className="text-sm text-gray-500 tracking-tight">
                        Target value
                      </div>
                      <Input
                        type="text"
                        className="w-22 text-sm px-2 py-0.5"
                        aria-label="Key result target value input"
                        value={keyResult.targetValue}
                        onChange={(e) =>
                          handleChangeKrValue(keyResult.uuid, "targetValue", e)
                        }
                      />
                    </div>
                  </div>
                )}
                <div className="flex items-start gap-1.5">
                  <div className="text-sm text-gray-500 tracking-tight w-18 mt-0.5">
                    Type
                  </div>
                  <div>
                    <div className="flex items-center gap-1.5">
                      <Select<GoalProgressType>
                        className="text-sm pl-2 py-0.5"
                        aria-label="Key result progress type input"
                        onChange={handleChangeKrProgressType(keyResult.uuid)}
                        value={keyResult.progressType}
                        options={getKeyResultProgressTypeOptions()}
                      />
                    </div>
                  </div>
                </div>
                {kpiOptions.length > 1 && (
                  <div className="flex items-start gap-1.5">
                    <div className="text-sm text-gray-500 tracking-tight w-18 mt-0.5">
                      Linked KPI
                    </div>
                    <div>
                      <div className="flex items-center gap-1.5">
                        <KpiPicker
                          loading={kpiLoading}
                          options={kpiOptions}
                          value={
                            keyResult.kpi
                              ? {
                                  value: keyResult.kpi.id,
                                  label: `${keyResult.kpi.id}`,
                                }
                              : noKpiOption
                          }
                          onSelectKpi={(option) =>
                            handleChangeKrKpi(keyResult.uuid, option)
                          }
                        />
                        {keyResult.kpi &&
                          keyResult.kpi?.id !== noKpiOption.value && (
                            <a
                              href={`/kpis/${keyResult.kpi.id}`}
                              rel="noreferrer"
                              target="_blank"
                              className="p-1 text-xs tracking-tight hover:bg-gray-100 rounded text-gray-500 hover:text-gray-800 hover:underline flex items-center gap-1"
                            >
                              <ExternalLinkIcon className="h-4 w-4" />
                            </a>
                          )}
                      </div>
                      {keyResult.kpi &&
                        keyResult.kpi?.id !== noKpiOption.value &&
                        keyResult.kpi.currentMeasurement && (
                          <a
                            href={`/kpis/${keyResult.kpi.id}`}
                            rel="noreferrer"
                            target="_blank"
                            className="mt-0.5 text-xs tracking-tight text-gray-500 hover:underline"
                          >
                            Current value:{" "}
                            {numeral(
                              keyResult.kpi.currentMeasurement.measurement
                            ).format()}
                          </a>
                        )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          ))}
          <div className={classNames(form.keyResults.length > 0 && "pt-2")}>
            <Button
              text={`Add ${label("key result")}`}
              theme={buttonTheme.roundDefault}
              mini
              onClick={handleClickAddKR}
            />
          </div>
        </div>
      </div>
      <div className="flex items-start gap-4 py-4 px-6">
        <DialogLabel className="mt-0.5">Align up</DialogLabel>
        <div className="flex-1 flex items-center gap-1 text-sm">
          {form.parentGoal ? (
            <>
              <button
                onClick={handleClearParentGoal}
                className="p-0.5 rounded hover:bg-gray-100"
              >
                <XIcon className="h-4 w-4 text-gray-500" />
              </button>
              <div>{form.parentGoal.htmlLabel}</div>
            </>
          ) : (
            <div className="w-64">
              <ArtifactSidebarGoalPickerCombobox
                goalScope={form.scope || GoalScope.Personal}
                alignmentType="parent"
                childGoalIds={form.childGoals.map(({ value }) => value)}
                onSelectGoal={handleSelectParentGoal}
              />
            </div>
          )}
        </div>
      </div>
      <div className="flex items-start gap-4 py-4 px-6">
        <DialogLabel className="mt-0.5">Align down</DialogLabel>
        <div className="flex-1 flex flex-col gap-2 text-sm">
          {form.childGoals.map((childGoal) => (
            <div className="flex items-center gap-1" key={childGoal.value}>
              <button
                onClick={() => handleClearChildGoal(childGoal)}
                className="p-0.5 rounded hover:bg-gray-100"
              >
                <XIcon className="h-4 w-4 text-gray-500" />
              </button>
              <div>{childGoal.htmlLabel}</div>
            </div>
          ))}
          <div className="w-64">
            <ArtifactSidebarGoalPickerCombobox
              goalScope={form.scope || GoalScope.Personal}
              alignmentType="child"
              parentGoalId={form.parentGoal?.value}
              childGoalIds={form.childGoals.map(({ value }) => value)}
              onSelectGoal={handleSelectChildGoal}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ArtifactCreationGoalFields;
