import { ClockIcon } from "@heroicons/react/outline";
import { range } from "lodash";
import moment from "moment";
import { CalendarEventFragmentFragment } from "types/graphql-schema";

import { classNames } from "@helpers/css";
import { formatTime, getUrl } from "@helpers/helpers";

import MonthEvent from "./month-event";

const Month = ({
  meetings,
  selectedDay,
  onChangeSelectedDay,
}: {
  meetings: CalendarEventFragmentFragment[];
  selectedDay: any;
  onChangeSelectedDay: any;
}) => {
  // COMPUTED DATA
  const firstDayOfMonth = moment(selectedDay)
    .startOf("month")
    .format("YYYY-MM-DD");
  const todayDate = moment().format("YYYY-MM-DD");
  const firstDayOfCalendar = moment(firstDayOfMonth).startOf("isoWeek");
  const lastDayOfCalendar = moment(firstDayOfMonth)
    .endOf("month")
    .endOf("isoWeek");
  const weeksBetween = lastDayOfCalendar.diff(
    firstDayOfCalendar,
    "weeks",
    true
  );
  const daysCount = lastDayOfCalendar.diff(firstDayOfCalendar, "days", true);
  const days = range(0, daysCount).map((i) => {
    const day = firstDayOfCalendar.clone().add(i, "day");
    const dayDate = day.format("YYYY-MM-DD");
    return {
      date: dayDate,
      isCurrentMonth: day.isSame(selectedDay, "month"),
      isSelected: moment(selectedDay).format("YYYY-MM-DD") === dayDate,
      isToday: dayDate === todayDate,
      events: meetings
        .filter(
          (meeting) =>
            dayDate === moment(meeting.startDatetime).format("YYYY-MM-DD")
        )
        .map((meeting) => ({
          ...meeting,
          isDisabled:
            meeting.meeting?.status === "cancelled" || meeting.meeting?.ignored,
        })),
    };
  });
  const selectedDayEvents = meetings.filter(
    (meeting) =>
      selectedDay === moment(meeting.startDatetime).format("YYYY-MM-DD")
  );

  // RENDER
  return (
    <div
      className="absolute inset-0 top-14 overflow-y-scroll"
      aria-label="Calendar month container"
    >
      <div className="shadow ring-1 ring-black ring-opacity-5 @4xl/calendar:flex @4xl/calendar:flex-auto @4xl/calendar:flex-col">
        <div className="grid grid-cols-7 gap-px border-b border-gray-300 bg-gray-200 text-center text-xs font-semibold leading-6 text-gray-700 @4xl/calendar:flex-none">
          <div className="bg-white py-2">
            M<span className="sr-only @md/calendar:not-sr-only">on</span>
          </div>
          <div className="bg-white py-2">
            T<span className="sr-only @md/calendar:not-sr-only">ue</span>
          </div>
          <div className="bg-white py-2">
            W<span className="sr-only @md/calendar:not-sr-only">ed</span>
          </div>
          <div className="bg-white py-2">
            T<span className="sr-only @md/calendar:not-sr-only">hu</span>
          </div>
          <div className="bg-white py-2">
            F<span className="sr-only @md/calendar:not-sr-only">ri</span>
          </div>
          <div className="bg-white py-2">
            S<span className="sr-only @md/calendar:not-sr-only">at</span>
          </div>
          <div className="bg-white py-2">
            S<span className="sr-only @md/calendar:not-sr-only">un</span>
          </div>
        </div>
        <div className="flex bg-gray-200 text-xs leading-6 text-gray-400 @4xl/calendar:flex-auto">
          <div
            className={`hidden w-full @4xl/calendar:grid @4xl/calendar:grid-cols-7 @4xl/calendar:grid-rows-${weeksBetween} @4xl/calendar:gap-px`}
          >
            {days.map((day) => (
              <div
                key={day.date}
                className={classNames(
                  day.isCurrentMonth ? "bg-white" : "bg-gray-50",
                  "relative py-1 px-2"
                )}
                aria-label={day.date}
              >
                <time
                  dateTime={day.date}
                  className={
                    day.isToday
                      ? "flex h-6 w-6 items-center justify-center rounded-full bg-indigo-600 font-semibold text-white"
                      : undefined
                  }
                >
                  {day?.date?.split("-")?.pop()?.replace(/^0/, "")}
                </time>
                <div className="mt-1 h-32 overflow-y-auto">
                  {day.events.length > 0 && (
                    <ol>
                      {day.events.map((event: any) => (
                        <MonthEvent key={event.id} event={event} />
                      ))}
                    </ol>
                  )}
                </div>
              </div>
            ))}
          </div>
          <div
            className={`isolate grid w-full grid-cols-7 grid-rows-${weeksBetween} gap-px @4xl/calendar:hidden`}
          >
            {days.map((day) => (
              <button
                key={day.date}
                type="button"
                onClick={() => onChangeSelectedDay(day.date)}
                className={classNames(
                  day.isCurrentMonth ? "bg-white" : "bg-gray-50",
                  (day.isSelected || day.isToday) && "font-semibold",
                  day.isSelected && "text-white",
                  !day.isSelected && day.isToday && "text-indigo-600",
                  !day.isSelected &&
                    day.isCurrentMonth &&
                    !day.isToday &&
                    "text-gray-900",
                  !day.isSelected &&
                    !day.isCurrentMonth &&
                    !day.isToday &&
                    "text-gray-500",
                  "flex h-16 flex-col py-1 px-2 hover:bg-gray-100 focus:z-10"
                )}
              >
                <div className="w-full flex justify-end">
                  <time
                    dateTime={day.date}
                    className={classNames(
                      (day.isSelected || day.isToday) &&
                        "flex h-6 w-6 items-center justify-center rounded-full",
                      day.isSelected && "bg-indigo-600",
                      !day.isSelected && day.isToday && "bg-indigo-100",
                      "ml-auto"
                    )}
                  >
                    {day?.date?.split("-")?.pop()?.replace(/^0/, "")}
                  </time>
                </div>
                <div className="mt-0.5">
                  {day.events.length > 0 && (
                    <span className="-mx-0.5 mt-1 flex flex-wrap-reverse">
                      {day.events.slice(0, 8).map((event: any) => (
                        <span
                          key={event.id}
                          className="mx-0.5 mb-1 h-1.5 w-1.5 rounded-full bg-gray-400"
                        />
                      ))}
                    </span>
                  )}
                </div>
              </button>
            ))}
          </div>
        </div>
      </div>
      {selectedDayEvents.length > 0 && (
        <div className="pt-8 pb-12 px-4 @md/calendar:px-6 @4xl/calendar:hidden">
          <ol className="flex flex-col gap-1 overflow-hidden bg-white text-sm">
            {selectedDayEvents.map((event: any) => (
              <li
                key={event.id}
                className={classNames(
                  "group flex py-2 px-4 rounded-lg border",
                  event.isDisabled
                    ? "bg-gray-50 border-transparent"
                    : event.meeting.draft
                    ? "bg-amber-50 hover:bg-amber-100 border-dashed border-amber-300"
                    : "bg-blue-50 hover:bg-blue-100 border-transparent"
                )}
              >
                <a
                  href={getUrl({
                    meetingId: event.id,
                    meetingGroupId: event.meetingGroupId,
                  })}
                >
                  <div
                    className={classNames(
                      "font-semibold",
                      event.isDisabled
                        ? "text-gray-400 line-through"
                        : event.meeting.draft
                        ? "text-amber-800"
                        : "text-blue-600"
                    )}
                  >
                    {event.title}
                  </div>
                  <time
                    dateTime={event.startDatetime}
                    className="mt-2 flex items-center text-gray-700"
                  >
                    <ClockIcon
                      className="mr-2 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                    {formatTime(moment(event.startDatetime).format("h:mma"))}
                  </time>
                </a>
              </li>
            ))}
          </ol>
        </div>
      )}
    </div>
  );
};

export default Month;
