import { useMutation, useQuery } from "@apollo/client";
import { ExternalLinkIcon } from "@heroicons/react/outline";
import { compact, uniqBy } from "lodash";
import numeral from "numeral";
import { ChangeEvent, useState } from "react";
import {
  GetKpisToConnectToKrQueryQuery,
  GetKpisToConnectToKrQueryQueryVariables,
  GoalArtifactSidebarFragmentFragment,
  GoalProgressType,
} from "types/graphql-schema";

import { KpiPicker } from "@apps/artifact-creation-dialog/artifact-creation-dialog";
import updateArtifactMutation from "@apps/artifact/graphql/update-artifact-mutation";
import Button, { buttonTheme } from "@components/button/button";
import { ComboboxGenericOption } from "@components/combobox/generic-combobox";
import Input from "@components/input/input";
import { Select, SelectOption } from "@components/select/select";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { getProgressTypeOptions, graphqlNone } from "@helpers/constants";
import {
  assertEdgesNonNull,
  getStartValueForProgressType,
  getTargetValueForProgressType,
} from "@helpers/helpers";

import getArtifactActivitiesQuery from "../graphql/get-artifact-activities-query";
import getKpisToConnectToKrQuery from "../graphql/get-kpis-to-connect-to-kr-query";

const noKpiOption = { value: graphqlNone, label: "None" };

const GoalEditValues = ({
  artifact,
  onClose,
}: {
  artifact: GoalArtifactSidebarFragmentFragment;
  onClose: () => void;
}) => {
  const [progressType, setProgressType] = useState(artifact.progressType);
  const [startValue, setStartValue] = useState(String(artifact.startValue));
  const [targetValue, setTargetValue] = useState(String(artifact.targetValue));
  const [kpi, setKpi] = useState<any | null>(
    artifact.kpi
      ? { ...artifact.kpi, label: artifact.kpi.title, value: artifact.kpi.id }
      : null
  );

  const { data, loading: kpiLoading } = useQuery<
    GetKpisToConnectToKrQueryQuery,
    GetKpisToConnectToKrQueryQueryVariables
  >(getKpisToConnectToKrQuery, {
    onError: onNotificationErrorHandler(),
  });
  const defaultKpiOptions = compact([
    noKpiOption,
    kpi?.id && {
      id: kpi.id,
      value: kpi.id,
      label: kpi.title,
      title: kpi.title,
      currentMeasurement: kpi.currentMeasurement,
    },
  ]);
  const kpis = data?.kpis ? assertEdgesNonNull(data?.kpis) : [];
  const kpiOptions = uniqBy(
    defaultKpiOptions.concat(
      kpis.map(({ id, title, currentMeasurement }) => ({
        id,
        value: id,
        label: title,
        title,
        currentMeasurement,
      }))
    ),
    "value"
  );

  const [updateArtifact] = useMutation(updateArtifactMutation);

  const handleChangeStartValue = (e: ChangeEvent<HTMLInputElement>) => {
    setStartValue(e.target.value);
  };
  const handleChangeTargetValue = (e: ChangeEvent<HTMLInputElement>) => {
    setTargetValue(e.target.value);
  };
  const handleSelectKpi = (option: ComboboxGenericOption<number>) => {
    setKpi(option.value === graphqlNone ? null : option);
  };
  const handleSelectProgressType = (option: SelectOption<GoalProgressType>) => {
    const automatedCalculationOption = getProgressTypeOptions().find(
      ({ value }) => value === GoalProgressType.AlignedAverage
    );
    if (
      option.value === GoalProgressType.AlignedAverage &&
      kpi &&
      automatedCalculationOption
    ) {
      window.alert(
        `Please unlink the KPI first before selecting "${automatedCalculationOption.label}" to avoid any conflicts in progress calculation.`
      );
    } else {
      setProgressType(option.value);
    }
  };

  const handleSaveGoalStartTargetValue = () => {
    const newStartValue = getStartValueForProgressType({
      progressType,
      startValue: Number(startValue),
    });
    const newTargetValue = getTargetValueForProgressType({
      progressType,
      targetValue: Number(targetValue),
    });
    updateArtifact({
      variables: {
        artifactId: artifact.id,
        additionalFields: {
          progressType,
          startValue: newStartValue,
          targetValue: newTargetValue,
          goalKpiId: kpi?.id || graphqlNone,
        },
      },
      optimisticResponse: {
        createOrUpdateArtifact: {
          artifact: {
            ...artifact,
            progressType,
            startValue: newStartValue,
            targetValue: newTargetValue,
            kpi,
          },
          __typename: "CreateOrUpdateArtifactMutation",
        },
      },
      onCompleted: () => {
        onClose();
        setStartValue(String(newStartValue));
        setTargetValue(String(newTargetValue));
      },
      refetchQueries: [getArtifactActivitiesQuery],
      onError: onNotificationErrorHandler(),
    });
  };

  return (
    <div className="flex-1 border rounded-lg bg-gray-50 p-4 flex flex-col gap-4">
      {progressType !== GoalProgressType.AlignedAverage &&
        progressType !== GoalProgressType.Boolean && (
          <div className="flex gap-2 items-center">
            <div className="font-medium text-sm w-28">Start value</div>
            <Input
              type="text"
              className="w-24 text-sm px-2 py-0.5"
              aria-label="Goal start value input"
              value={startValue}
              onChange={handleChangeStartValue}
            />
          </div>
        )}
      {progressType !== GoalProgressType.AlignedAverage &&
        progressType !== GoalProgressType.Boolean && (
          <div className="flex gap-2 items-center">
            <div className="font-medium text-sm w-28">Target value</div>
            <Input
              type="text"
              className="w-24 text-sm px-2 py-0.5 rounded border"
              aria-label="Goal target value input"
              value={targetValue}
              onChange={handleChangeTargetValue}
            />
          </div>
        )}
      <div className="flex gap-2 items-start">
        <div className="font-medium text-sm w-28 pt-0.5">Type</div>
        <div>
          <Select<GoalProgressType>
            className="text-sm pl-2 py-0.5"
            onChange={handleSelectProgressType}
            value={progressType}
            options={getProgressTypeOptions()}
          />
        </div>
      </div>
      {progressType !== GoalProgressType.AlignedAverage &&
        kpiOptions.length > 1 && (
          <div className="flex gap-2 items-start">
            <div className="font-medium text-sm w-28 pt-0.5">Linked KPI</div>
            <div className="">
              <div className="flex items-center gap-2">
                <KpiPicker
                  loading={kpiLoading}
                  options={kpiOptions}
                  value={kpi || noKpiOption}
                  onSelectKpi={handleSelectKpi}
                />
                {kpi?.id !== noKpiOption.value && (
                  <a
                    href={`/kpis/${kpi?.id}`}
                    rel="noreferrer"
                    target="_blank"
                    className="p-1 hover:bg-gray-100 rounded text-gray-500 hover:text-gray-800"
                  >
                    <ExternalLinkIcon className="h-4 w-4" />
                  </a>
                )}
              </div>
              {kpi?.id !== noKpiOption.value && kpi?.currentMeasurement && (
                <a
                  href={`/kpis/${kpi.id}`}
                  rel="noreferrer"
                  target="_blank"
                  className="mt-1 ml-0.5 text-xs tracking-tight text-gray-500 hover:underline"
                >
                  Latest value recorded:{" "}
                  {numeral(kpi.currentMeasurement.measurement).format()}
                </a>
              )}
            </div>
          </div>
        )}
      <div className="flex items-center gap-2 ml-30">
        <Button
          text="Save"
          small
          theme={buttonTheme.primary}
          onClick={handleSaveGoalStartTargetValue}
        />
        <Button
          text="Cancel"
          small
          theme={buttonTheme.default}
          onClick={onClose}
        />
      </div>
    </div>
  );
};

export default GoalEditValues;
