import { Droppable } from "@hello-pangea/dnd";
import { withErrorBoundary } from "@sentry/react";
import { useEffect, useRef } from "react";
import { MeetingViewMeetingNodeNewPageFragmentFragment } from "types/graphql-schema";

import Error from "@components/error/error";
import { classNames } from "@helpers/css";
import { assertEdgesNonNull } from "@helpers/helpers";

import { MeetingWebsocketProviderContext } from "../context";
import useMeetingWebsocketProvider from "../use-meeting-websocket-provider";
import AddTopicHover from "./add-topic-hover";
import DragAndDrop from "./drag-and-drop";
import Topic from "./topic";
import TopicCreateForm from "./topic-create-form";

const Meeting = ({
  meeting,
  isInExtension = false, // true when this view appears in an extension
  opensTopicsInSidebar,
  currentPageMeeting = null,
}: {
  meeting: MeetingViewMeetingNodeNewPageFragmentFragment;
  isInExtension?: boolean; // true when this view appears in an extension
  opensTopicsInSidebar: boolean;
  currentPageMeeting?: MeetingViewMeetingNodeNewPageFragmentFragment | null;
}) => {
  const createTopicFormRef = useRef<HTMLDivElement | null>(null);
  const meetingsGroupedById = {} as {
    [key: number]: MeetingViewMeetingNodeNewPageFragmentFragment;
  };
  meetingsGroupedById[meeting.id] = meeting;
  const topics = assertEdgesNonNull(meeting.topics);

  const meetingWebsocketContext = useMeetingWebsocketProvider(meeting);

  useEffect(() => {
    return function cleanup() {
      const websocketConfigs = Object.values(meetingWebsocketContext);
      websocketConfigs.forEach(({ providerWebsocket, ydoc }) => {
        providerWebsocket?.destroy();
        ydoc?.destroy();
      });
    };
  }, []);

  if (!meeting.meetingGroup) {
    return <Error title="This meeting does not exist." />;
  }

  return (
    <DragAndDrop meetingsGroupedById={meetingsGroupedById}>
      <div aria-label={`Meeting: ${meeting.title}`}>
        <div>
          <Droppable droppableId={`meeting-${meeting.id}`}>
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {topics.length > 0 && (
                  <ul className="fs-mask border-b" aria-label="Topic list">
                    <MeetingWebsocketProviderContext.Provider
                      value={meetingWebsocketContext}
                    >
                      {topics.map((topic, index) => (
                        <div
                          key={topic.id}
                          className={classNames(isInExtension && "px-4")}
                        >
                          <Topic
                            index={index}
                            topic={topic}
                            meeting={meeting}
                            isInExtension={isInExtension}
                            opensInSidebar={opensTopicsInSidebar}
                            currentPageMeeting={currentPageMeeting}
                          />
                          {index >= 0 && index < topics.length - 1 && (
                            <AddTopicHover
                              meeting={meeting}
                              afterTopicId={topic.id}
                            />
                          )}
                        </div>
                      ))}
                    </MeetingWebsocketProviderContext.Provider>
                    {provided.placeholder}
                  </ul>
                )}
                <div
                  ref={createTopicFormRef}
                  className={classNames(isInExtension && "px-4")}
                >
                  <TopicCreateForm
                    key={`create-topic-form-${meeting.id}`}
                    meetingGroup={meeting.meetingGroup}
                    meeting={meeting}
                  />
                </div>
              </div>
            )}
          </Droppable>
        </div>
      </div>
    </DragAndDrop>
  );
};

export default withErrorBoundary(Meeting, {
  fallback: <Error description={"The meeting could not be rendered."} />,
});
