import { useMutation } from "@apollo/client";
import copy from "copy-to-clipboard";
import { compact } from "lodash";
import { ChangeEvent, FormEvent, useLayoutEffect, useState } from "react";
import {
  EmailMeetingInvitesNewPageMutationMutation,
  EmailMeetingInvitesNewPageMutationMutationVariables,
  EmailMeetingNotesNewPageMutationMutation,
  EmailMeetingNotesNewPageMutationMutationVariables,
  MeetingParticipantFragmentFragment,
  MeetingViewMeetingNodeNewPageFragmentFragment,
  UserStatus,
} from "types/graphql-schema";

import AttendeeInput, {
  Attendee,
} from "@apps/meeting-dialog/components/attendee-input";
import AttendeeList from "@apps/meeting-dialog/components/attendee-list";
import updateMeetingMutation from "@apps/meeting-new/graphql/update-meeting-mutation";
import { errorNotificationVar, successNotificationVar } from "@cache/cache";
import Button, { buttonTheme } from "@components/button/button";
import { InputLabel } from "@components/input/input";
import Modal from "@components/modal/modal";
import ModalTitle from "@components/modal/modal-title";
import CustomTextareaAutosize from "@components/textarea-autosize/textarea-autosize";
import {
  onNotificationErrorHandler,
  useNotificationError,
} from "@components/use-error/use-error";
import { classNames, inputBorderClassName } from "@helpers/css";
import { assertEdgesNonNullWithStringId } from "@helpers/helpers";

import sendMeetingInvitesMutation from "../../graphql/send-meeting-invites-mutation";
import sendMeetingNotesMutation from "../../graphql/send-meeting-notes-mutation";

interface Props {
  meeting: MeetingViewMeetingNodeNewPageFragmentFragment;
  isInviteDialog: boolean;
  onClose: () => void;
}

enum ShareType {
  email = "email",
  url = "url",
}

function isNotWithFalsyUser(
  obj: MeetingParticipantFragmentFragment
): obj is MeetingParticipantFragmentFragment & {
  user: NonNullable<MeetingParticipantFragmentFragment["user"]>;
} {
  return obj.user !== null && obj.user !== undefined;
}

const ShareNotesDialog: React.FC<Props> = ({
  meeting,
  isInviteDialog,
  onClose,
}) => {
  const [shareType, setShareType] = useState(ShareType.email);
  const { onError } = useNotificationError({
    errorMatches: [
      {
        match:
          "You have sent too many summary emails, please wait before sending another",
        title: "Cannot send more summary emails",
        description:
          "You have sent too many summary emails, please wait before sending another.",
      },
      {
        match: "Free users can only send meeting notes to up to",
        title: "Cannot send more summary emails",
        description:
          "Free users can only send meeting notes to up to 20 non-participant users",
      },
    ],
  });
  const participantNodes = assertEdgesNonNullWithStringId(meeting.participants);
  const participantsWithUser = participantNodes.filter(isNotWithFalsyUser);
  const participants = participantsWithUser
    .map((node) => ({
      ...node.user,
      role: node.role,
      participantStatus: node.status,
    }))
    .filter((node) => {
      return !isInviteDialog || node.status === UserStatus.Inactive;
    });

  const [emailRecipients, setEmailRecipients] =
    useState<Attendee[]>(participants);
  const [, setIsRerendered] = useState(false); // force rerendering of message autosize input
  const [message, setMessage] = useState("");
  const [sendMeetingNotes, { loading: loadingSendNotes }] = useMutation<
    EmailMeetingNotesNewPageMutationMutation,
    EmailMeetingNotesNewPageMutationMutationVariables
  >(sendMeetingNotesMutation, {
    onError,
    onCompleted: () => {
      successNotificationVar({ title: "Meeting notes sent!" });
      onClose();
    },
  });
  const [updateMeeting, { loading: loadingUpdateMeeting }] = useMutation(
    updateMeetingMutation,
    {
      onError,
    }
  );
  const [sendMeetingInvites, { loading: loadingSendInvites }] = useMutation<
    EmailMeetingInvitesNewPageMutationMutation,
    EmailMeetingInvitesNewPageMutationMutationVariables
  >(sendMeetingInvitesMutation, {
    onError,
    onCompleted: () => {
      successNotificationVar({ title: "Meeting invites sent!" });
      onClose();
    },
  });
  const handleChangeRecipients = (emails: Attendee[]) => {
    setEmailRecipients(emails);
  };
  const handleChangeMessage = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(e.currentTarget.value);
  };
  const handleSubmitForm = (e: FormEvent) => {
    e.preventDefault();
    const sendEmail = isInviteDialog ? sendMeetingInvites : sendMeetingNotes;
    sendEmail({
      onError,
      variables: {
        meetingId: meeting?.id,
        recipientEmails: compact(emailRecipients.map(({ email }) => email)),
        message,
      },
    });
  };

  const handleToggleShareUrl = () => {
    const newVal = !meeting.allowExternalViewers;
    updateMeeting({
      variables: {
        meetingId: meeting.id,
        allowExternalViewers: newVal,
      },
      optimisticResponse: {
        updateMeeting: {
          meeting: {
            ...meeting,
            allowExternalViewers: newVal,
            writableSharingUrl: "",
          },
        },
      },
      onError: onNotificationErrorHandler(),
    });
  };

  const handleCopySharingUrl = () => {
    if (meeting.allowExternalViewers && meeting.writableSharingUrl) {
      copy(meeting.writableSharingUrl);
      successNotificationVar({ title: "Copied!", timeout: 1000 });
    } else {
      errorNotificationVar({
        title: "The meeting url cannot be copied!",
        timeout: 2000,
      });
    }
  };

  useLayoutEffect(() => setIsRerendered(true), []);

  if (!meeting.meetingGroup) return null;

  return (
    <Modal onClose={onClose} alignedTop>
      <div className="px-4 py-5 sm:p-6" aria-label="Share meeting notes dialog">
        <ModalTitle onClose={onClose}>
          {isInviteDialog ? "Invite to Topicflow" : "Share meeting notes"}
        </ModalTitle>

        <div className="flex gap-2 mt-4">
          <Button
            text="Send by email"
            small
            theme={
              shareType === ShareType.email
                ? buttonTheme.tabActive
                : buttonTheme.tab
            }
            onClick={() => setShareType(ShareType.email)}
          />
          <Button
            text="Share url"
            small
            theme={
              shareType === ShareType.url
                ? buttonTheme.tabActive
                : buttonTheme.tab
            }
            onClick={() => setShareType(ShareType.url)}
          />
        </div>
        <div className="border rounded-lg p-4 mt-4">
          {shareType === ShareType.email ? (
            <form onSubmit={handleSubmitForm}>
              <div className="flex flex-col justify-between gap-8 max-w-96">
                <div className="w-full">
                  <InputLabel label="Recipients" className="mb-1" />
                  {!isInviteDialog && (
                    <AttendeeInput
                      isFormalOneonone={meeting.meetingGroup.isFormalOneonone}
                      attendees={emailRecipients}
                      onChange={handleChangeRecipients}
                    />
                  )}
                  <AttendeeList
                    validDomains={compact(meeting.organization?.domains || [])}
                    allowRemoveCurrentUser
                    attendees={emailRecipients}
                    showRoleSelector={false}
                    onChange={handleChangeRecipients}
                    isFormalOneonone={!!meeting.meetingGroup?.isFormalOneonone}
                  />
                </div>
                <div className="w-full">
                  <InputLabel label="Message" />
                  <CustomTextareaAutosize
                    className="mt-1 border hover:border-gray-300 rounded-lg text-sm px-2 py-1.5 focus:outline-0 focus:ring-0 w-full"
                    minRows={2}
                    defaultValue=""
                    placeholder="Add any message you'd like to share with recipients"
                    aria-label="Meeting notes email message input"
                    id="meeting-notes-message"
                    onChange={handleChangeMessage}
                    cacheMeasurements={true}
                  />
                </div>
              </div>
              <div className="mt-6 flex justify-start gap-4">
                {(!isInviteDialog || emailRecipients.length > 0) && (
                  <Button
                    theme="primary"
                    type="submit"
                    disabled={
                      loadingSendNotes ||
                      loadingSendInvites ||
                      emailRecipients.length === 0
                    }
                    aria-label="Send meeting notes submit button"
                  >
                    Send
                    {emailRecipients.length > 0 &&
                      ` to ${emailRecipients.length} ${
                        emailRecipients.length === 1 ? "person" : "people"
                      }`}
                  </Button>
                )}
                <Button
                  type="button"
                  disabled={loadingSendNotes || loadingSendInvites}
                  aria-label="Cancel send meeting notes"
                  onClick={onClose}
                >
                  Cancel
                </Button>
              </div>
            </form>
          ) : (
            <div className="flex flex-col gap-4 text-sm">
              <label className="flex gap-2 items-center text-gray-600">
                <input
                  type="checkbox"
                  checked={!!meeting.allowExternalViewers}
                  onChange={handleToggleShareUrl}
                />
                <div>Anyone with the link can access this meeting</div>
              </label>
              <div className="flex items-center gap-2">
                <input
                  type="text"
                  value={meeting.writableSharingUrl || ""}
                  placeholder="https://"
                  disabled={
                    !meeting.allowExternalViewers ||
                    !meeting.writableSharingUrl ||
                    loadingUpdateMeeting
                  }
                  onChange={() => {}}
                  className={classNames(
                    inputBorderClassName,
                    "px-2 py-1 w-full max-w-96"
                  )}
                />
                <Button
                  text="Copy link"
                  theme={buttonTheme.primary}
                  small
                  disabled={
                    !meeting.allowExternalViewers ||
                    !meeting.writableSharingUrl ||
                    loadingUpdateMeeting
                  }
                  onClick={handleCopySharingUrl}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default ShareNotesDialog;
