import { useMutation } from "@apollo/client";
import { Popover } from "@headlessui/react";
import { PencilAltIcon, TrashIcon, XIcon } from "@heroicons/react/outline";
import { useCallback, useState } from "react";
import { TbArrowBigLeft } from "react-icons/tb";
import { usePopper } from "react-popper";

import getSidebarDataQuery from "@apps/main/graphql/get-sidebar-data-query";
import deleteDraftMeetingMutation from "@apps/meeting-new/graphql/delete-draft-meeting-mutation";
import { currentUserVar } from "@cache/cache";
import Button, { buttonTheme } from "@components/button/button";
import { useLink } from "@components/link/link";
import Loading from "@components/loading/loading";
import Tooltip from "@components/tooltip/tooltip";
import { useNotificationError } from "@components/use-error/use-error";
import { meetingEditType } from "@helpers/constants";
import { classNames } from "@helpers/css";
import useConfirm from "@helpers/hooks/use-confirm";

import cancelMeetingMutation from "../graphql/cancel-meeting-mutation";
import { meetingDialogAction } from "../meeting-dialog";

const MeetingDialogButtons = ({
  action,
  draft,
  isRecurring,
  meetingId,
  organizerId,
  onClickEdit,
  onClickView,
  onClose,
}: {
  action: meetingDialogAction;
  draft: boolean;
  isRecurring: boolean;
  meetingId?: number | null;
  organizerId?: number | null;
  onClickEdit: () => void;
  onClickView: () => void;
  onClose: () => void;
}) => {
  const [referenceElement, setReferenceElement] =
    useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] = useState<null | HTMLDivElement>(
    null
  );
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "bottom-end",
  });
  const [editType, setEditType] = useState(meetingEditType.singleEvent);
  const currentUser = currentUserVar();
  const isCurrentUserTheOrganizer = organizerId === currentUser.id;
  const link = useLink();
  const { onError } = useNotificationError({
    errorMatches: [
      {
        match: "only the organizer of a meeting can cancel it",
        title: "The meeting can only be cancelled by its organizer.",
      },
    ],
  });

  const {
    ConfirmationDialog: ConfirmationDeleteDialog,
    confirm: confirmDelete,
  } = useConfirm(
    "Are you sure?",
    "Are you sure you want to delete this draft meeting?"
  );
  const {
    ConfirmationDialog: ConfirmationCancelDialog,
    confirm: confirmCancel,
  } = useConfirm(
    "Are you sure?",
    "Are you sure you want to cancel this meeting?"
  );

  const [deleteDraftMeeting, { loading: loadingDeleteDraft }] = useMutation(
    deleteDraftMeetingMutation,
    {
      refetchQueries: [getSidebarDataQuery],
    }
  );
  const [cancelMeeting, { loading: loadingCancel }] = useMutation(
    cancelMeetingMutation,
    {
      refetchQueries: [getSidebarDataQuery],
    }
  );

  const handleClickCancel = () => {
    if (draft || !isRecurring) {
      handleCancelMeeting();
    }
  };

  const handleCancelMeeting = useCallback(async () => {
    if (draft) {
      const confirmation = await confirmDelete();
      if (confirmation) {
        deleteDraftMeeting({
          variables: { meetingId },
          onCompleted: () => {
            link.redirect("/");
          },
          onError,
        });
      }
    } else {
      const confirmation = await confirmCancel();
      if (confirmation) {
        cancelMeeting({
          variables: { meetingId, editType },
          onCompleted: () => {
            link.redirect("/");
          },
          onError,
        });
      }
    }
  }, [
    cancelMeeting,
    confirmCancel,
    confirmDelete,
    deleteDraftMeeting,
    draft,
    editType,
    link,
    meetingId,
    onError,
  ]);

  const showEditButton = action !== meetingDialogAction.publish;

  if (loadingCancel || loadingDeleteDraft) {
    return (
      <div className="z-dropdown absolute -top-4 right-0 bg-white rounded-full p-2 flex gap-2">
        <Loading size="5" mini />
      </div>
    );
  }

  return (
    <div className="z-dropdown absolute -top-4 right-0 bg-white rounded-full p-1 flex gap-2">
      <ConfirmationCancelDialog />
      <ConfirmationDeleteDialog />
      {showEditButton && action !== meetingDialogAction.edit && meetingId && (
        <Tooltip
          text={
            isCurrentUserTheOrganizer
              ? ""
              : "This meeting can only be edited by the organizer"
          }
        >
          {/* Needs the span for the tooltip */}
          <span>
            <button
              type="button"
              disabled={!isCurrentUserTheOrganizer}
              aria-label="Edit meeting button"
              className={classNames(
                "p-1 rounded-full text-gray-400 focus:outline-none",
                isCurrentUserTheOrganizer && "hover:bg-gray-100"
              )}
              onClick={onClickEdit}
            >
              <span className="sr-only">Edit meeting</span>
              <PencilAltIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </span>
        </Tooltip>
      )}
      {showEditButton && action === meetingDialogAction.edit && meetingId && (
        <button
          type="button"
          disabled={!isCurrentUserTheOrganizer}
          aria-label="View meeting button"
          className={classNames(
            "p-1 rounded-full text-gray-400 focus:outline-none",
            isCurrentUserTheOrganizer && "hover:bg-gray-100"
          )}
          onClick={onClickView}
        >
          <span className="sr-only">View meeting</span>
          <TbArrowBigLeft className="h-5 w-5" aria-hidden="true" />
        </button>
      )}
      {meetingId && (
        <Popover className="relative flex">
          <>
            <Tooltip
              text={
                isCurrentUserTheOrganizer
                  ? ""
                  : "This meeting can only be cancelled by the organizer"
              }
            >
              {/* Needs the span for the tooltip */}
              <span>
                <Popover.Button
                  ref={setReferenceElement}
                  type="button"
                  aria-label="Delete meeting button"
                  className={classNames(
                    "p-1 rounded-full text-gray-400 focus:outline-none",
                    isCurrentUserTheOrganizer && "hover:bg-gray-100"
                  )}
                  onClick={handleClickCancel}
                  disabled={!isCurrentUserTheOrganizer}
                >
                  <span className="sr-only">Delete meeting</span>
                  <TrashIcon className="h-5 w-5" aria-hidden="true" />
                </Popover.Button>
              </span>
            </Tooltip>
            {!draft && isRecurring && (
              <Popover.Panel
                className="absolute bottom-12 left-1/2 z-dropdown w-96 -translate-x-1/2 transform"
                aria-label="Meeting dialog recurring cancel popover"
                ref={setPopperElement}
                style={styles.popper}
                {...attributes.popper}
              >
                {({ close }) => (
                  <div className="rounded-lg drop-shadow-lg bg-white p-4 border flex flex-col gap-4">
                    <div className="font-medium">Cancel meeting</div>
                    <div className="flex flex-col gap-2 text-sm">
                      <label className="flex items-center gap-2">
                        <input
                          type="radio"
                          name="recurring-event-changes"
                          value={meetingEditType.singleEvent}
                          checked={editType === meetingEditType.singleEvent}
                          onChange={() =>
                            setEditType(meetingEditType.singleEvent)
                          }
                        />{" "}
                        This meeting
                      </label>
                      <label className="flex items-center gap-2">
                        <input
                          type="radio"
                          name="recurring-event-changes"
                          value={meetingEditType.thisEventAndFuture}
                          checked={
                            editType === meetingEditType.thisEventAndFuture
                          }
                          onChange={() =>
                            setEditType(meetingEditType.thisEventAndFuture)
                          }
                        />{" "}
                        This and future meetings
                      </label>

                      <label className="flex items-center gap-2">
                        <input
                          type="radio"
                          name="recurring-event-changes"
                          value={meetingEditType.allEvents}
                          checked={editType === meetingEditType.allEvents}
                          onChange={() =>
                            setEditType(meetingEditType.allEvents)
                          }
                        />{" "}
                        All meetings
                      </label>
                    </div>
                    <div className="flex gap-2">
                      <Button
                        text="Cancel"
                        aria-label="Cancel cancel recurring meeting button"
                        theme={buttonTheme.default}
                        className="w-1/2"
                        onClick={() => close()}
                      />
                      <Button
                        text="Apply"
                        type="submit"
                        theme={buttonTheme.primary}
                        className="w-1/2"
                        onClick={(e) => {
                          handleCancelMeeting();
                          close();
                        }}
                      />
                    </div>
                  </div>
                )}
              </Popover.Panel>
            )}
          </>
        </Popover>
      )}
      <button
        type="button"
        aria-label="Meeting dialog close button"
        className="p-1 rounded-full text-gray-400 hover:bg-gray-100 focus:outline-none"
        onClick={onClose}
      >
        <span className="sr-only">Close panel</span>
        <XIcon className="h-5 w-5" aria-hidden="true" />
      </button>
    </div>
  );
};

export default MeetingDialogButtons;
