import { DuplicateIcon } from "@heroicons/react/outline";
import copy from "copy-to-clipboard";
import { useRef, useState } from "react";

import ExplorerFilters from "@apps/explorer/components/filters";
import ExplorerContent, {
  getResultUniqueId,
} from "@apps/explorer/explorer-content";
import {
  ExplorerFilterType,
  getDefaultFilters,
  getExplorerFiltersUrl,
} from "@apps/explorer/helpers";
import useExplorerQuery from "@apps/explorer/use-explorer-query";
import { successNotificationVar } from "@cache/cache";
import Button, { buttonTheme } from "@components/button/button";
import Modal from "@components/modal/modal";
import ModalTitle from "@components/modal/modal-title";
import useDebounce from "@components/use-debounce/use-debounce";
import { classNames } from "@helpers/css";

type Props = {
  initialFilters?: ExplorerFilterType;
  meetingGroupId?: number | null;
  selectable?: boolean;
  onClickCTASelection?: ((results: any, filters: any) => void) | null;
  onClickResult?: null | ((result: any) => void);
  onClose: (value?: boolean) => void;
};

export const SearchModal = ({
  initialFilters = {},
  onClickResult = null,
  selectable = false,
  onClickCTASelection = null,
  onClose,
}: Props) => {
  const [selectionActivated, setSelectionActivated] = useState(false);
  const [selection, setSelection] = useState<string[]>([]);
  const defaultFilters = getDefaultFilters();
  const focusRef = useRef(null);
  const [filters, setFilters] = useState({
    ...defaultFilters,
    ...initialFilters,
  });
  const search = useDebounce(filters.search, 500);
  const {
    loading,
    loadingFirstTime,
    fetchMore,
    results,
    pageInfo,
    needSearchQueryForIntegrations,
  } = useExplorerQuery({ ...filters, search }, 10);

  const handleChangeFilters = (updatedFilters: any) => {
    const newFilters = { ...defaultFilters, ...filters, ...updatedFilters };
    setFilters(newFilters);
  };
  const handleClickBookmark = (bookmarkFilters: ExplorerFilterType) => {
    handleChangeFilters(bookmarkFilters);
  };

  const handleClickMore = () => {
    fetchMore({
      variables: { after: pageInfo.endCursor, merge: true },
    });
  };

  const handleCheckResult = (result: any) => () => {
    const resultUniqueId = getResultUniqueId(result);
    if (selection.includes(resultUniqueId)) {
      setSelection(selection.filter((id) => id !== resultUniqueId));
    } else {
      setSelection(selection.concat(resultUniqueId));
    }
  };

  const handleClickCopy = () => {
    copy(getExplorerFiltersUrl(filters));
    successNotificationVar({ title: "Copied!", timeout: 2000 });
  };

  const handleClickSelectionCTA = () => {
    const selectedResults = results.filter((result) => {
      const uniqueResultId = getResultUniqueId(result);
      return selection.includes(uniqueResultId);
    });
    if (onClickCTASelection) {
      onClickCTASelection(selectionActivated ? selectedResults : null, filters);
    }
  };

  const handleClickAddTableToMeetingCTA = () => {
    if (onClickCTASelection) {
      onClickCTASelection(null, filters);
    }
  };

  return (
    <Modal
      open
      onClose={onClose}
      dialogClassName="md:max-w-7xl sm:mt-8 md:mt-12"
      dialogContainerClassName="items-start p-1 sm:p-4"
      initialFocus={focusRef}
    >
      <div
        className="z-10000 bg-white flex flex-col gap-4 p-4 @container min-h-[400px]"
        aria-label="Search dialog"
      >
        <ModalTitle onClose={onClose}>Search</ModalTitle>
        <div className="overflow-y-scroll flex flex-col flex-1 @3xl:flex-row gap-6 w-full">
          <div className="@3xl:w-64 @3xl:flex flex-col gap-6">
            <ExplorerFilters
              filters={filters}
              onChangeFilters={handleChangeFilters}
              onClickBookmark={handleClickBookmark}
            />
          </div>
          <div className="flex-1">
            {selectable && (
              <div
                className={classNames(
                  "mb-3 flex gap-2 text-sm tracking-tight justify-between"
                )}
              >
                <button
                  className="ml-2 text-sm hover:underline tracking-tight text-gray-600"
                  onClick={() => setSelectionActivated(!selectionActivated)}
                >
                  {selectionActivated
                    ? "Cancel selection"
                    : "Select results..."}
                </button>
                <div className="flex gap-2">
                  {selection.length > 0 && (
                    <Button
                      theme={buttonTheme.roundBlue}
                      mini
                      onClick={handleClickSelectionCTA}
                    >
                      Add selection ({selection.length}) to meeting agenda
                    </Button>
                  )}
                  <Button
                    theme={buttonTheme.roundBluePrimary}
                    mini
                    onClick={handleClickAddTableToMeetingCTA}
                  >
                    Add table to meeting agenda
                  </Button>
                  <Button
                    theme={buttonTheme.roundDefault}
                    mini
                    onClick={handleClickCopy}
                  >
                    <DuplicateIcon className="h-4 w-4" />
                  </Button>
                </div>
              </div>
            )}
            <ExplorerContent
              className="flex-1"
              needSearchQueryForIntegrations={needSearchQueryForIntegrations}
              loading={loading}
              loadingFirstTime={loadingFirstTime}
              filters={filters}
              results={results}
              pageInfo={pageInfo}
              onClickMore={handleClickMore}
              onClickResult={onClickResult ? onClickResult : undefined}
              onSelectResult={handleCheckResult}
              selectionActivated={selectionActivated}
              selection={selection}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SearchModal;
