import { last, orderBy, sortBy, sumBy } from "lodash";
import moment from "moment";
import { useState } from "react";
import { RiTeamLine } from "react-icons/ri";
import {
  GoalOverviewRelatedEntityGoalFragmentFragment,
  TeamNode,
  UserNode,
} from "types/graphql-schema";

import GoalProgressPillWithPopover, {
  GoalProgressPillView,
} from "@apps/goal-progress-pill-with-popover/goal-progress-pill-with-popover";
import useLabel from "@apps/use-label/use-label";
import Avatar from "@components/avatar/avatar";
import AppLink from "@components/link/link";
import Table, {
  TableBody,
  TableBodyCell,
  TableBodyRow,
  TableContainer,
  TableHeadCell,
  TableHeadRow,
  TableSortDir,
} from "@components/table/table";
import {
  UserComboboxOption,
  UserComboboxOptionType,
} from "@components/user-combobox/user-combobox-list";

const getMostRecentUpdate = (
  goals: GoalOverviewRelatedEntityGoalFragmentFragment[]
) => {
  const timestamps = goals.reduce((memo, goal) => {
    if (goal.activities.edges?.[0]?.node?.created) {
      return [...memo, goal.activities.edges[0].node.created];
    }
    return memo;
  }, [] as string[]);
  if (timestamps.length === 0) {
    return "No updates.";
  }
  return moment(last(sortBy(timestamps))).format("LLL");
};

const getOverallProgress = (
  goals: GoalOverviewRelatedEntityGoalFragmentFragment[]
) => {
  if (goals.length === 0) {
    return null;
  }
  const sum = sumBy(goals, "progress");
  if (sum === 0) {
    return 0;
  }
  return Math.floor(sum / goals.length);
};

enum SortKeyEnum {
  label = "label",
  lastUpdate = "lastUpdate",
  avgProgress = "avgProgress",
}

type UserOrTeamType =
  | Pick<UserNode, "__typename" | "id" | "name">
  | Pick<TeamNode, "__typename" | "id" | "title">;

const GoalOverviewRelatedEntitiesTable = ({
  selected,
  entities,
}: {
  entities: (UserOrTeamType & {
    goals: GoalOverviewRelatedEntityGoalFragmentFragment[];
  })[];
  selected: UserComboboxOption;
}) => {
  const [sortKey, setSortKey] = useState<SortKeyEnum>(SortKeyEnum.label);
  const [sortDir, setSortDir] = useState<TableSortDir>("asc");
  const label = useLabel();

  const sortedEntities = orderBy(
    entities,
    (entity) => {
      if (sortKey === SortKeyEnum.label && entity.__typename === "UserNode")
        return [entity.name];
      if (sortKey === SortKeyEnum.label && entity.__typename === "TeamNode")
        return [entity.title];
      if (sortKey === SortKeyEnum.lastUpdate)
        return [getMostRecentUpdate(entity.goals)];
      if (sortKey === SortKeyEnum.avgProgress)
        return [getOverallProgress(entity.goals)];
    },
    [sortDir]
  );

  const handleClickSorting = (newSortKey: SortKeyEnum) => () => {
    if (newSortKey !== sortKey) {
      setSortKey(newSortKey);
    } else {
      setSortDir(sortDir === "asc" ? "desc" : "asc");
    }
  };

  return (
    <TableContainer className="shadow border-0">
      <Table>
        <TableHeadRow>
          <TableHeadCell
            sorted={sortKey === SortKeyEnum.label ? sortDir : undefined}
            onClick={handleClickSorting(SortKeyEnum.label)}
          >
            Name
          </TableHeadCell>
          <TableHeadCell
            width="42"
            sorted={sortKey === SortKeyEnum.avgProgress ? sortDir : undefined}
            onClick={handleClickSorting(SortKeyEnum.avgProgress)}
          >
            Avg progress
          </TableHeadCell>
          <TableHeadCell>
            {label("goal", { capitalize: true, pluralize: true })}
          </TableHeadCell>
          <TableHeadCell
            width="48"
            sorted={sortKey === SortKeyEnum.lastUpdate ? sortDir : undefined}
            onClick={handleClickSorting(SortKeyEnum.lastUpdate)}
          >
            Last update
          </TableHeadCell>
        </TableHeadRow>
        <TableBody>
          {sortedEntities.map((entity) => (
            <TableBodyRow key={entity.id}>
              <TableBodyCell>
                {entity.__typename === "UserNode" ? (
                  <div className="text-gray-800 flex items-center gap-1.5">
                    <Avatar user={entity} size={5} />
                    <AppLink
                      to={`/goals/user/${entity.id}`}
                      className="hover:underline"
                    >
                      {entity.name}
                    </AppLink>
                  </div>
                ) : entity.__typename === "TeamNode" ? (
                  <div className="text-gray-800 flex items-center gap-1.5">
                    <RiTeamLine className="w-5 h-5 text-gray-500" />
                    <AppLink
                      to={`/goals/team/${entity.id}`}
                      className="hover:underline"
                    >
                      {entity.title}
                    </AppLink>
                  </div>
                ) : null}
              </TableBodyCell>
              <TableBodyCell>
                <div className="flex items-center gap-1">
                  {entity.goals.length > 0
                    ? `${getOverallProgress(entity.goals)}%`
                    : null}
                </div>
              </TableBodyCell>
              <TableBodyCell>
                <div className="flex items-center gap-1">
                  {entity.goals.map((goal) => (
                    <GoalProgressPillWithPopover
                      key={goal.id}
                      artifact={goal}
                      pillView={GoalProgressPillView.pillOnly}
                    />
                  ))}
                </div>
              </TableBodyCell>
              <TableBodyCell>
                <div className="flex items-center gap-1">
                  {getMostRecentUpdate(entity.goals)}
                </div>
              </TableBodyCell>
            </TableBodyRow>
          ))}
          {sortedEntities.length === 0 && (
            <TableBodyRow>
              <TableBodyCell colSpan={4}>
                No{" "}
                {selected.type === UserComboboxOptionType.USER
                  ? "reports"
                  : selected.type === UserComboboxOptionType.TEAM
                  ? `${label("team")} members`
                  : label("team", { pluralize: true })}
                .
              </TableBodyCell>
            </TableBodyRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default GoalOverviewRelatedEntitiesTable;
