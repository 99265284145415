import { useMutation } from "@apollo/client";
import { TrashIcon } from "@heroicons/react/outline";
import {
  FormEvent,
  KeyboardEvent,
  KeyboardEventHandler,
  MouseEvent,
  useRef,
  useState,
} from "react";
import {
  DeleteTeamMutationMutation,
  DeleteTeamMutationMutationVariables,
  UpdateTeamMutationMutation,
  UpdateTeamMutationMutationVariables,
} from "types/graphql-schema";

import updateTeamMutation from "@apps/org-settings/graphql/update-team-mutation";
import useLabel from "@apps/use-label/use-label";
import Avatar from "@components/avatar/avatar";
import Button, { buttonTheme } from "@components/button/button";
import GraphqlError from "@components/error/graphql-error";
import Input, { InputLabel } from "@components/input/input";
import Modal from "@components/modal/modal";
import ModalTitle from "@components/modal/modal-title";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { isCommandEnterEvent } from "@helpers/helpers";

import deleteTeamMutation from "../graphql/delete-team-mutation";
import getOrganizationTeamsQuery from "../graphql/get-organization-teams-query";
import TeamMemberCombobox from "./team-member-combobox";

const emptyTeam = {
  id: null,
  title: "",
  members: { edges: [] },
};

const TeamEditDialog = ({
  organization = null,
  team = null,
  onClose = null,
}: {
  organization: any;
  team?: any;
  onClose: any;
}) => {
  const label = useLabel();
  const focusRef = useRef<HTMLElement>(null);
  const [formTeam, setFormTeam] = useState(team || emptyTeam);
  const [updateTeam] = useMutation<
    UpdateTeamMutationMutation,
    UpdateTeamMutationMutationVariables
  >(updateTeamMutation);
  const [deleteTeam] = useMutation<
    DeleteTeamMutationMutation,
    DeleteTeamMutationMutationVariables
  >(deleteTeamMutation);

  const error = null;

  const handleSave = (
    e:
      | MouseEvent<HTMLButtonElement>
      | FormEvent<HTMLFormElement>
      | KeyboardEvent<HTMLInputElement>
  ) => {
    e.preventDefault();
    updateTeam({
      variables: {
        id: formTeam.id,
        title: formTeam.title,
        memberIds: formTeam.members.edges.map(
          ({ node }: { node: any }) => node.id
        ),
        organizationId: organization.id,
      },
      refetchQueries: [getOrganizationTeamsQuery],
      onCompleted: () => onClose(false),
      onError: onNotificationErrorHandler(),
    });
  };

  const handleClose = () => {
    onClose(false);
  };

  const handleDelete = () => {
    if (
      team &&
      window.confirm(`Are you sure you want to delete this ${label("team")}?`)
    ) {
      deleteTeam({
        variables: { id: team.id },
        refetchQueries: [getOrganizationTeamsQuery],
        onCompleted: handleClose,
        onError: onNotificationErrorHandler(),
      });
    }
  };

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (isCommandEnterEvent(e)) {
      handleSave(e);
    }
  };

  const handleAddMember = (member: any) => {
    const newMemberEdges = [...formTeam.members.edges, { node: member }];
    setFormTeam({ ...formTeam, members: { edges: newMemberEdges } });
  };

  const handleRemoveMember = (memberId: number) => () => {
    const newMemberEdges = formTeam.members.edges.filter(
      ({ node }: { node: any }) => node.id !== memberId
    );
    setFormTeam({ ...formTeam, members: { edges: newMemberEdges } });
  };

  return (
    <Modal
      open
      onClose={onClose}
      initialFocus={focusRef}
      aria-label="Team edit dialog"
    >
      <div className="p-6 bg-white rounded-md">
        <div className="mb-4">
          <ModalTitle onClose={handleClose}>
            {team
              ? `Edit ${formTeam.title}`
              : `New ${label("team", { capitalize: true })}`}
          </ModalTitle>
        </div>
        <form className="mt-4 flex flex-col gap-6" onSubmit={handleSave}>
          <div className=" max-w-96">
            <Input
              label={`${label("team", { capitalize: true })} name`}
              aria-label="Team title input"
              value={formTeam.title}
              onKeyDown={handleKeyDown}
              onChange={(e) =>
                setFormTeam({ ...formTeam, title: e.target.value })
              }
            />
          </div>
          <div className="flex flex-col gap-4">
            <div className="max-w-96">
              <InputLabel
                label={`${label("team", { capitalize: true })} members`}
                className="mb-1"
              />
              <TeamMemberCombobox
                excludeUserIds={formTeam.members.edges.map(
                  ({ node }: { node: any }) => node.id
                )}
                onSelect={handleAddMember}
              />
            </div>
            <div className="flex flex-col text-sm text-gray-600 divide-y border-t border-b empty:hidden">
              {formTeam.members.edges.map(({ node }: { node: any }) => (
                <div
                  key={node.id}
                  className="flex justify-between items-center gap-2 px-3 py-1.5 hover:bg-gray-50"
                >
                  <div className="flex items-center gap-2">
                    <Avatar user={node} size="4" />
                    {node.name}
                  </div>
                  <button
                    aria-label="Delete team member button"
                    onClick={handleRemoveMember(node.id)}
                    className="rounded hover:bg-gray-200 text-gray-500 hover:text-gray-800 px-1 py-0.5"
                  >
                    <TrashIcon className="h-4 w-4" />
                  </button>
                </div>
              ))}
            </div>
          </div>
          {error && (
            <div>
              <GraphqlError error={error} />
            </div>
          )}
          <div className="flex justify-between items-center gap-2 mt-4">
            <div className="flex gap-2">
              <Button
                type="submit"
                small
                theme={buttonTheme.primary}
                onClick={handleSave}
                disabled={formTeam.title.trim().length === 0}
              >
                Save
              </Button>
              <Button small onClick={handleClose}>
                Cancel
              </Button>
            </div>
            <div>
              <Button
                small
                theme={buttonTheme.redDanger}
                onClick={handleDelete}
              >
                Delete
              </Button>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default TeamEditDialog;
