import { useQuery } from "@apollo/client";
import moment from "moment";
import { useState } from "react";
import {
  GetMeetingRecentlyCompletedAssessmentsNewPageQueryQuery,
  GetMeetingRecentlyCompletedAssessmentsNewPageQueryQueryVariables,
  MeetingViewMeetingNodeNewPageFragmentFragment,
} from "types/graphql-schema";

import getMeetingRecentlyCompletedAssessmentsQuery from "@apps/meeting-new/graphql/get-meeting-recently-completed-assessments-query";
import Layout from "@components/layout/layout";
import Link from "@components/link/link";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { classNames } from "@helpers/css";
import { assertEdgesNonNull } from "@helpers/helpers";

const RecentlyCompletedAssessments = ({
  meeting,
}: {
  meeting: MeetingViewMeetingNodeNewPageFragmentFragment;
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { data } = useQuery<
    GetMeetingRecentlyCompletedAssessmentsNewPageQueryQuery,
    GetMeetingRecentlyCompletedAssessmentsNewPageQueryQueryVariables
  >(getMeetingRecentlyCompletedAssessmentsQuery, {
    notifyOnNetworkStatusChange: true,
    variables: { meetingId: meeting.id },
    onError: onNotificationErrorHandler(),
  });
  const assessments = data?.meeting?.recentlyCompletedAssessments
    ? assertEdgesNonNull(data.meeting.recentlyCompletedAssessments)
    : [];

  if (assessments.length === 0) {
    return null;
  }

  return (
    <Layout.SidebarSection
      title="Assessments"
      expandedUiPreferenceKey="meetingSidebarRecentlyCompletedAssessmentsContainerExpanded"
    >
      <Layout.SidebarSubSection
        title={`Recently completed`}
        isExpanded={isExpanded}
        onToggleIsExpanded={setIsExpanded}
        count={assessments.length}
      >
        <div
          className={classNames(
            "divide-y divide-gray-200",
            "border border-gray-200 rounded-lg bg-white"
          )}
        >
          {assessments.map((assessment) => {
            return (
              <div className="pl-3 p-2 text-sm" key={assessment.id}>
                <div>
                  <Link
                    to={`/assessments/assessment/${assessment.id}`}
                    className="text-blue-link hover:underline"
                  >
                    {assessment.responder?.id === assessment.target?.id
                      ? `${assessment.responder?.name} submitted a self-assessement`
                      : `
                    ${assessment.responder?.name} assessed ${assessment.target?.name}`}
                    .
                  </Link>
                </div>
                <div className="text-xs flex justify-between mt-1 text-gray-500">
                  <Link
                    to={`/assessments/assessment/${assessment.id}`}
                    className="hover:underline"
                  >
                    {assessment.complianceProgram?.title}
                  </Link>
                  <span className="text-gray-400">
                    {moment(assessment.submittedDatetime).format("LLL")}
                  </span>
                </div>
              </div>
            );
          })}
        </div>
      </Layout.SidebarSubSection>
    </Layout.SidebarSection>
  );
};
export default RecentlyCompletedAssessments;
