import { Combobox, Popover, PopoverButtonProps } from "@headlessui/react";
import {
  CheckIcon,
  SearchIcon,
  SelectorIcon,
  XIcon,
} from "@heroicons/react/outline";
import { ChangeEvent, useMemo, useState } from "react";
import { usePopper } from "react-popper";

import Loading from "@components/loading/loading";
import {
  classNames,
  inputBorderClassName,
  inputFocusClassName,
} from "@helpers/css";

export type ComboboxGenericOption<T = unknown> = {
  label: string;
  htmlLabel?: JSX.Element;
  value: T;
  description?: string;
};

type Props<T> = Omit<PopoverButtonProps<"div">, "children"> & {
  children?: React.FC<{
    value: ComboboxGenericOption<T> | null;
    clearable?: boolean;
    onClickButton?: () => void;
    setReferenceElement: (element: HTMLButtonElement) => void;
  }>;
  disabled?: boolean;
  width?: string;
  value: ComboboxGenericOption<T> | null;
  options: ComboboxGenericOption<T>[];
  loading?: boolean;
  clearable?: boolean;
  createable?: boolean;
  name?: string;
  className?: string;
  buttonClassName?: string;
  placeholder?: string;
  searchPlaceholder?: string;
  query?: string;
  setQuery?: (value: string) => void;
  onClearValue?: () => void;
  onChangeValue: (
    option: ComboboxGenericOption<T>,
    meta: { name: string }
  ) => void;
  onAddNewValue?: (optionLabel: string) => void;
  onClickButton?: () => void;
};

export const ComboboxGenericClearButton = ({
  onClearValue,
}: {
  onClearValue: () => void;
}) => (
  <button
    onClick={(e) => {
      e.preventDefault();
      e.stopPropagation();
      onClearValue();
    }}
    className="px-2 py-1 flex items-center text-gray-600"
    aria-label="Combobox clear button"
  >
    <span className="px-1 py-1 rounded hover:bg-gray-100">
      <XIcon className="h-4 w-4" />
    </span>
  </button>
);

const ComboboxGeneric = <T,>({
  disabled = false,
  width,
  value,
  options = [],
  loading,
  clearable = false,
  createable,
  className = "",
  onClearValue,
  onChangeValue,
  onClickButton,
  onAddNewValue,
  name = "combobox",
  placeholder = "Not set",
  searchPlaceholder = "Search...",
  children,
  query,
  setQuery,
  buttonClassName = "",
  ...props
}: Props<T>) => {
  const [internalQuery, setInternalQuery] = useState("");
  const searchInputValue = query !== undefined ? query : internalQuery;
  const [referenceElement, setReferenceElement] =
    useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  );
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "bottom-start",
  });

  const filteredOptions = useMemo(() => {
    return options.filter(({ label }) =>
      label.toLowerCase().includes(internalQuery.toLowerCase().trim())
    );
  }, [options, internalQuery]);

  const handleChangeSearchInputValue = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setInternalQuery(event.target.value);
    if (setQuery) {
      setQuery(event.target.value);
    }
  };

  const handleChange =
    (close: () => void) => (option: ComboboxGenericOption<T> | string) => {
      close();
      if (option === searchInputValue && onAddNewValue) {
        onAddNewValue(searchInputValue);
        setInternalQuery("");
      } else {
        onChangeValue(option as ComboboxGenericOption<T>, { name });
      }
    };

  return (
    <Popover className={classNames("relative", className)} {...props}>
      {children ? (
        children({
          value,
          setReferenceElement,
          clearable,
          onClickButton,
        })
      ) : (
        <div
          className={classNames(
            "flex justify-between bg-white rounded-md",
            inputBorderClassName,
            inputFocusClassName
          )}
        >
          <Popover.Button
            className={classNames(
              "px-4 py-2 flex gap-2 w-full text-sm text-left items-center justify-between min-w-0 rounded-md",
              !disabled && "bg-white",
              disabled && "bg-gray-100",
              buttonClassName
            )}
            onClick={onClickButton}
            ref={setReferenceElement}
            disabled={disabled}
          >
            {value?.htmlLabel ? (
              <div className="truncate">{value.htmlLabel}</div>
            ) : value?.label ? (
              <div className="truncate">{value.label}</div>
            ) : (
              <div className="truncate text-gray-400">{placeholder}</div>
            )}
            {(!clearable || !value) && !disabled && (
              <span>
                <SelectorIcon className="h-4 w-4" />
              </span>
            )}
          </Popover.Button>
          {clearable && onClearValue && value && !disabled && (
            <ComboboxGenericClearButton onClearValue={onClearValue} />
          )}
        </div>
      )}

      <Popover.Panel
        className={classNames(
          "z-dropdown bg-white border rounded-md shadow-md",
          width && `w-${width}`,
          !width && "min-w-64"
        )}
        aria-label="Combobox popover"
        ref={setPopperElement}
        style={styles.popper}
        {...attributes.popper}
      >
        {({ close }) => (
          <Combobox onChange={handleChange(close)} disabled={disabled}>
            <div className="relative">
              <div className="relative w-full cursor-default">
                <Combobox.Input
                  autoFocus
                  value={searchInputValue}
                  onChange={handleChangeSearchInputValue}
                  placeholder={searchPlaceholder}
                  aria-label="Combobox search input"
                  className="relative w-full pl-11 pr-9 py-2 text-left cursor-default sm:text-sm border-b focus:outline-0 focus:ring-0 bg-transparent"
                />
                <span className="absolute inset-y-0 left-0 -top-1 flex items-center pl-4">
                  <SearchIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
                {loading && (
                  <Loading
                    mini
                    size="4"
                    className="absolute inset-y-0 right-2 top-2.5 flex items-center"
                  />
                )}
              </div>
              {(filteredOptions.length > 0 || createable) && (
                <Combobox.Options
                  static
                  className="text-base overflow-auto focus:outline-none sm:text-sm max-h-72 max-w-96 divide-y divide-gray-75"
                >
                  {filteredOptions.map((option, i) => (
                    <Combobox.Option
                      key={`${option.value}`}
                      value={option}
                      className={({ active }) =>
                        classNames(
                          active ? "text-white bg-blue-600" : "text-gray-900",
                          "cursor-default select-none relative py-2 pl-4 pr-9",
                          filteredOptions.length - 1 === i && "rounded-b-md"
                        )
                      }
                    >
                      {({ active }) => (
                        <div>
                          <div className="flex justify-between gap-2 pr-5 min-w-0">
                            <div className="flex flex-1 gap-2">
                              {option.htmlLabel
                                ? option.htmlLabel
                                : option.label}
                            </div>
                            {value?.value === option.value && (
                              <span
                                className={classNames(
                                  active ? "text-white" : "text-indigo-600",
                                  "absolute inset-y-0 right-0 flex items-center pr-4"
                                )}
                              >
                                <CheckIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              </span>
                            )}
                          </div>
                          {option.description && (
                            <div
                              className={classNames(
                                "text-2xs tracking-tight",
                                active ? "text-white/70" : "text-gray-500"
                              )}
                            >
                              {option.description}
                            </div>
                          )}
                        </div>
                      )}
                    </Combobox.Option>
                  ))}

                  {createable && searchInputValue !== "" && (
                    <Combobox.Option
                      value={searchInputValue}
                      className={({ active }) =>
                        classNames(
                          active ? "text-white bg-blue-600" : "text-gray-900",
                          "cursor-default select-none relative py-2 pl-4 pr-9"
                        )
                      }
                    >
                      <div>
                        <div className="flex justify-between gap-2 pr-5 min-w-0">
                          <div className="flex flex-1 gap-2">
                            Add new '{searchInputValue}'
                          </div>
                        </div>
                      </div>
                    </Combobox.Option>
                  )}
                </Combobox.Options>
              )}
              {!loading &&
                internalQuery !== "" &&
                filteredOptions.length === 0 &&
                !createable && (
                  <div className="py-14 px-4 text-center sm:px-14">
                    <p className="mt-4 text-sm text-gray-900">No results.</p>
                  </div>
                )}
            </div>
          </Combobox>
        )}
      </Popover.Panel>
    </Popover>
  );
};

export default ComboboxGeneric;
