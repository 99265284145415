import { useMutation } from "@apollo/client";
import { Listbox } from "@headlessui/react";
import { SelectorIcon } from "@heroicons/react/outline";
import { DecisionState } from "types/graphql-schema";

import getArtifactActivitiesQuery from "@apps/artifact-sidebar/graphql/get-artifact-activities-query";
import getDashboardGoalsQuery from "@apps/dashboard-new/graphql/get-dashboard-goals-query";
import Select from "@components/select/select";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { classNames, listBoxButtonClassName } from "@helpers/css";

import updateArtifactMutation from "../graphql/update-artifact-mutation";

const DecisionStateComponent = ({
  artifact,
  className,
  iconSize = "4",
}: {
  artifact: {
    id: number;
    decisionState?: DecisionState | null;
    canUpdate: {
      permission: boolean;
    };
  };
  className?: string;
  iconSize?: string;
}) => {
  const [updateArtifact] = useMutation(updateArtifactMutation);

  const handleChangeState = ({ value: newState }: { value: DecisionState }) => {
    updateArtifact({
      variables: {
        artifactId: artifact.id,
        additionalFields: { decisionState: newState },
      },
      optimisticResponse: {
        createOrUpdateArtifact: {
          artifact: {
            ...artifact,
            decisionState: newState,
          },
          __typename: "CreateOrUpdateArtifactMutation",
        },
      },
      refetchQueries: [getDashboardGoalsQuery, getArtifactActivitiesQuery],
      onError: onNotificationErrorHandler(),
    });
  };

  return (
    <Select
      disabled={!artifact.canUpdate.permission}
      options={[
        {
          value: DecisionState.Draft,
          label: "Draft",
        },
        {
          value: DecisionState.Decided,
          label: "Decided",
        },
      ]}
      value={artifact.decisionState}
      onChange={handleChangeState}
    >
      {({ selected, setReferenceElement, disabled }) => (
        <Listbox.Button
          className={classNames(
            listBoxButtonClassName({ disabled }),
            className
          )}
          aria-label="Decision state dropdown button"
          ref={setReferenceElement}
        >
          <div className="flex items-center gap-1.5">{selected?.label}</div>
          {!disabled && (
            <SelectorIcon
              className={`h-${iconSize} w-${iconSize} text-gray-400 pointer-events-none`}
              aria-hidden="true"
            />
          )}
        </Listbox.Button>
      )}
    </Select>
  );
};

export default DecisionStateComponent;
