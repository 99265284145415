import { PencilIcon, UsersIcon } from "@heroicons/react/outline";
import { useCallback, useState } from "react";
import { MeetingDialogFragmentFragment } from "types/graphql-schema";

import MeetingDialog, {
  meetingDialogAction,
} from "@apps/meeting-dialog/meeting-dialog";
import { currentUserVar } from "@cache/cache";
import Avatar from "@components/avatar/avatar";
import Button, { buttonTheme } from "@components/button/button";
import AppLink from "@components/link/link";
import { assertEdgesNonNullWithStringId, getUrl } from "@helpers/helpers";

const UnscheduledMeeting = ({
  meeting,
  onMeetingUpdated,
}: {
  meeting: MeetingDialogFragmentFragment;
  onMeetingUpdated: (data: { meeting: MeetingDialogFragmentFragment }) => void;
}) => {
  const [isShowingMeetingDialog, setIsShowingMeetingDialog] = useState(false);
  const currentUser = currentUserVar();
  const participants = assertEdgesNonNullWithStringId(meeting.participants);
  const otherParticipant = participants.find(
    (participant) => participant.user && participant.user.id !== currentUser.id
  );

  const handleCloseModal = useCallback(() => {
    setIsShowingMeetingDialog(false);
  }, []);

  return (
    <>
      {isShowingMeetingDialog && (
        <MeetingDialog
          meetingId={meeting.id}
          meetingGroupId={meeting.meetingGroup?.id}
          onClose={handleCloseModal}
          defaultAction={meetingDialogAction.edit}
          onSaved={onMeetingUpdated}
        />
      )}
      <div className="text-sm w-1/3 border rounded-lg p-4 flex items-center justify-between">
        <AppLink
          className="flex-1 hover:underline truncate pr-4"
          key={meeting.id}
          to={getUrl({
            meetingGroupId: meeting.meetingGroupId,
            meetingId: meeting.id,
          })}
        >
          {meeting.title}
        </AppLink>
        <div className="flex items-center gap-2">
          {meeting.meetingGroup?.isFormalOneonone && otherParticipant ? (
            <Avatar user={otherParticipant.user} size={4} />
          ) : (
            <div className="flex items-center gap-0.5">
              <UsersIcon className="h-4 w-4" />{" "}
              {meeting.participants?.totalCount || 1}
            </div>
          )}
          <Button
            theme={buttonTheme.text}
            icon
            className="text-inherit"
            onClick={() => setIsShowingMeetingDialog(true)}
          >
            <PencilIcon className="h-4 w-4" />
          </Button>
        </div>
      </div>
    </>
  );
};

export default UnscheduledMeeting;
